import React from 'react';
import GesnCatalog from "../components/catalogs/GesnCatalog";
import FsemCatalog from "../components/catalogs/FsemCatalog";

const Fsem = () => {
    return (
        <div>
            <FsemCatalog/>
        </div>
    );
};

export default Fsem;