import React from 'react';
import cl from './ToolTip.module.css';

const ToolTip = ({data, isVisible, clickCoords}) => {

    const tooltipClasses = cl.toolTip

    return (
        <div className={isVisible ? tooltipClasses : tooltipClasses + " hidden"} style={{left: clickCoords.clientX - 300, top: clickCoords.clientY}}>
            <table className="text-sm text-gray-500 dark:text-gray-400 border-2 border-[#343A40]">
                <tbody>
                {
                    data['prices'].map((value) => {
                        return (
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <td className="px-2 py-1 border border-black text-[12px] text-black text-left cursor-pointer">
                                    {value.factory}
                                </td>
                                <td className="px-2 py-1 w-[100px] border border-black text-[12px] font-bold text-black text-right cursor-pointer">
                                    {(value.price).toLocaleString('ru')} руб.
                                </td>
                            </tr>
                        )
                    })
                }
                </tbody>
            </table>
        </div>
    );
};

export default ToolTip;