import React from 'react';

const ResourceModelSummaryTableRow = ({item, isEndRow}) => {

    const classes = "px-4 py-1 text-black"

    return (
        <tr className="border border-[#343A40]">
            <td className={isEndRow ? classes + " font-bold" : classes}>{item.title}</td>
            <td className={isEndRow ? classes + " text-right font-bold": classes + " text-right"}>{(item.cost).toLocaleString('ru')}</td>
            <td className="px-4 py-1 text-black text-right">{isEndRow ? '' : item.smr_weight}</td>
            <td className="px-4 py-1 text-black text-right">{isEndRow ? '' : item.rm_weight}</td>
        </tr>
    );
};

export default ResourceModelSummaryTableRow;