import React, {useEffect, useState} from 'react';
import UncsService from "../../API/UncsService";
import {useNavigate} from "react-router-dom";

const CreateUnc = ({title, setVisible, getUncList}) => {

    // GET: /uncs-list - получение предустановленого списка расценок

    const route = useNavigate()
    const [uncListData, setUncListData] = useState([])
    const [selectedUncId, setSelectedUncId] = useState(0)
    let flag = 0

    const getUncListAll = () => {
        const uncList = UncsService.getUncListAll()
        uncList.then((value) => setUncListData(value.data))
    }

    useEffect(() => {
        getUncListAll()
    }, [])

    const postUnc = (event) => {
        event.preventDefault()
        const postUncResponse = UncsService.postUnc({id: selectedUncId})
        postUncResponse.then((value) => {
            setVisible(false)
            getUncList()
        })
    }

    return (
        <div>
            <div className="mb-5 text-lg font-bold mt-[-10px]">{title}</div>
            <form style={{width: 500}}>
                <div className="mb-6">
                    <label htmlFor="uncs" className="block mb-1 text-sm font-normal text-gray-500 dark:text-gray-400">Номер расценки УНЦ:</label>
                    <select id="uncs"
                            onChange={(event) => setSelectedUncId(Number(event.target.value))}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                        {
                            uncListData.map((item, index) => <option value={item.id} key={item.id}>{item.unc_number}</option>)
                        }
                    </select>
                </div>
                <div className="flex justify-between">
                    <div></div>
                    <div>
                        <button
                            type="button"
                            className="text-white mr-3 bg-orange-500 hover:bg-orange-700 focus:ring-4 focus:outline-none focus:ring-orange-500 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-orange-600 dark:hover:bg-orange-700 dark:focus:ring-orange-800"
                        >Отмена
                        </button>
                        <button
                            type="submit"
                            onClick={(event) => postUnc(event)}
                            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        >Создать
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default CreateUnc;