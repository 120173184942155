import React from 'react';
import ResourceModelsTableRow from "./ResourceModelsTableRow";
import DownloadIcon from "../icons/DownloadIcon";
import DownloadFileService from "../../API/DownloadFileService";

const ResourceModelsTable = ({data}) => {

    const downloadRmList = () => {
        const download = DownloadFileService.downloadRmList()
        download.then((value) => {
            // показать уведомление об успешном скачивании
        })
    }

    return (
        <div>
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                    <th scope="col" className="px-6 py-3 w-6 text-center border">
                        №
                    </th>
                    <th scope="col" className="px-6 py-3 w-[200px] text-center border">
                        Идентификатор РМ
                    </th>
                    <th scope="col" className="px-6 py-3 text-center border">
                        Наименование ресурсной модели
                    </th>
                    <th scope="col" className="px-6 py-3 w-[180px] text-center border">
                        Стоимость, тыс. р.
                    </th>
                    <th scope="col" className="px-6 py-3 text-center border">
                        <div onClick={() => downloadRmList()} title="Скачать список РМ">
                            <DownloadIcon/>
                        </div>
                    </th>
                </tr>
                </thead>
                <tbody>
                {data.map((item, index) =>
                    <ResourceModelsTableRow data={item} index={index + 1} key={item.id}/>
                )}
                </tbody>
            </table>
        </div>
    );
};

export default ResourceModelsTable;