import React, {useContext, useEffect, useMemo} from 'react';
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../../context";

const Card = ({data}) => {

    const router = useNavigate();
    // const sumInMillions = useMemo(() => Math.round(data.cost / 1000) / 1000, [data.cost])

    return (
        <div onClick={() => router(`/uncs/${data.id}`)}
             className="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700 hover:shadow-xl hover:bg-gray-100 cursor-pointer">
            <div className="w-full h-full flex flex-col justify-between">
                <div>
                    <p className="text-sm font-normal text-gray-500 dark:text-gray-400">Идентификатор:</p>
                    <h5 className="mb-2 text-sm font-semibold tracking-tight text-gray-900 dark:text-white">{data.code}</h5>

                    <p className="text-sm font-normal text-gray-500 dark:text-gray-400">Наименование:</p>
                    <h5 className="mb-2 text-sm font-semibold tracking-tight text-gray-900 dark:text-white"
                    >{data.title}</h5>
                </div>

                <div>
                    <div className="w-100 h-0.5 bg-gray-300 my-2"></div>
                    <div className="flex">
                        <p className="text-sm font-normal text-gray-500 dark:text-gray-400">Стоимость:</p>
                        <p className="text-sm ml-2 font-semibold tracking-tight text-gray-900 dark:text-white">{data.cost > 0 ? (data.cost).toLocaleString('ru') : 0} руб.</p>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Card;