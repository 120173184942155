import React from 'react';
import PlusIcon from "../icons/PlusIcon";
import CatalogPriceTableRowBc from "./CatalogPriceTableRowBc";


const CatalogPriceTableBc = ({priceTable, checkedIds, setCheckedIds, setBcShowParam, isModal}) => {

    return (
        <div className="">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                    {
                        priceTable.columns.map((item) => (<th scope="col" className="px-6 py-1 text-center border border-gray-500">
                            {item}
                        </th>))
                    }
                    {
                        isModal
                        ?
                            (
                                <th scope="col" className="px-4 py-1 text-center border border-gray-500">
                                    <PlusIcon/>
                                </th>
                            )
                        : ""
                    }
                </tr>
                </thead>
                <tbody>
                {priceTable.data.map((item, index) =>
                    <CatalogPriceTableRowBc
                        isModal={isModal}
                        data={item}
                        index={index}
                        key={item.id}
                        checkedIds={checkedIds}
                        setCheckedIds={setCheckedIds}
                        setBcShowParam={setBcShowParam}
                    />
                )}
                </tbody>
            </table>
        </div>
    );
};

export default CatalogPriceTableBc;