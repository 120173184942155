import React, {useEffect, useState} from 'react';
import FactoryService from "../../API/FactoryService";
import FactoryTableRow from "./FactoryTableRow";

const FactoriesList = () => {

    const [factories, setFactories] = useState([])

    useEffect(() => {
        getFactories()
    }, [])

    const getFactories = () => {
        const allFactories = FactoryService.getAll();
        allFactories.then((value) => {
            setFactories(value.data)
        })
    }

    return (
        <div>
            <table className="w-full mt-4 mb-10 text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                    <th scope="col" className="px-6 py-3 w-6 text-center border">
                        №
                    </th>
                    <th scope="col" className="px-6 py-3  text-center border">
                        Наименование
                    </th>
                    <th scope="col" className="px-6 py-3  text-center border">
                        Адрес производства
                    </th>
                    <th scope="col" className="px-6 py-3 w-[200px] text-center border">
                        Статус БЦ
                    </th>
                </tr>
                </thead>
                <tbody>
                {factories.map((item, index) =>
                    <FactoryTableRow data={item} index={index} key={item.id}/>
                )}
                </tbody>
            </table>
        </div>
    );
};

export default FactoriesList;