import React, {useState} from 'react';
import PlusIcon from "../icons/PlusIcon";
import CatalogPriceTableRow from "./CatalogPriceTableRow";


const CatalogPriceTable = ({data, checkedIds, setCheckedIds, firstPriceTitle, secondPriceTitle, isModal}) => {

    console.log("firstPriceTitle", firstPriceTitle )
    console.log("secondPriceTitle", secondPriceTitle )

    return (
        <div className="">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                    <th scope="col" className="px-6 py-1 w-[150px] text-center border border-gray-500">
                        Шифр
                    </th>
                    <th scope="col" className="px-6 py-1 text-center border border-gray-500">
                        Наименование характеристики
                    </th>
                    <th scope="col" className="px-6 py-1 w-[50px] text-center border border-gray-500">
                        Ед. изм.
                    </th>
                    <th scope="col" className="px-6 py-1 w-[100px] text-center border border-gray-500">
                        {firstPriceTitle}
                    </th>
                    <th scope="col" className="px-6 py-1 w-[100px] text-center border border-gray-500">
                        {secondPriceTitle}
                    </th>
                    {
                        isModal
                        ?
                            (
                                <th scope="col" className="px-4 py-1 text-center border border-gray-500">
                                    <PlusIcon/>
                                </th>
                            )
                            : ""
                    }

                </tr>
                </thead>
                <tbody>
                {data.map((item, index) =>
                    <CatalogPriceTableRow isModal={isModal} data={item} index={index} key={item.id} checkedIds={checkedIds} setCheckedIds={setCheckedIds}/>
                )}
                </tbody>
            </table>
        </div>
    );
};

export default CatalogPriceTable;