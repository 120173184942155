import React from 'react';
import cl from './MainModal.module.css'

const MainModal = ({children, visible, setVisible}) => {

    const rootClasses = [cl.mainModal]

    if (visible) {
        rootClasses.push(cl.active)
    }

    return (
        <div className={rootClasses.join(' ')} onClick={() => setVisible(false)}>
            <div className={cl.mainModalContent} onClick={(e) => e.stopPropagation()}>
                <div className="flex justify-between">
                    <div></div>
                    <div className={cl.mainModalClose} onClick={() => setVisible(false)}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path
                                d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z"/>
                        </svg>
                    </div>
                </div>

                {children}

            </div>
        </div>
    );
};

export default MainModal;