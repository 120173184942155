import React from 'react';
import FactoryBcStatus from "./FactoryBcStatus";
import {Link} from "react-router-dom";

const FactoryTableRow = ({data, index}) => {
    return (
        <tr className="bg-white border-b text-gray-900 dark:bg-gray-800 dark:border-gray-700">
            <th scope="row"
                className="px-2 py-1 font-medium text-center whitespace-nowrap dark:text-white border">
                {index + 1}
            </th>
            <td className="px-2 py-1 border-r hover:underline">
                <Link to={`/factories/${data.id}`}>{data.name}</Link>
            </td>
            <td className="px-2 py-1 border-r hover:underline">
                <Link to={`/factories/${data.id}`}>{data.address}</Link>
            </td>
            <td className="px-2 py-1 text-center border-r">
                <div className="w-full pl-10">
                    {
                        <FactoryBcStatus data={data.bcStatus}/>
                    }
                </div>
            </td>
        </tr>
    );
};

export default FactoryTableRow;