import React, {useRef, useState} from 'react';
import RmService from "../../API/RmService";


const UploadRmFile = ({
                          title,
                          setVisible,
                          setVisibleNotify,
                          getResourceModels,
                          setNotifyText,
                          setNotifyColor,
                          setNotifyData,
                          setIsSuccess
                      }) => {

    const [files, setFiles] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const ref = useRef();

    const resetFile = () => {
        ref.current.value = "";
    }

    const prepareFile = (event) => {
        event.preventDefault()
        setFiles(event.target.files)
    }

    const postFiles = (filesList) => {
        if (filesList.length > 0) {
            const fileItem = filesList.pop()

            setIsLoading(true)

            const postRmFile = RmService.postExcelRM(fileItem)
            postRmFile.then((value) => {
                postFiles(filesList)
            }).catch((error) => {
                setIsSuccess(false)
                setNotifyColor("border-red-600")
                setNotifyText(error.response.data.result)
                setNotifyData(error.response.data?.messages)
                setVisibleNotify(true)
                getResourceModels()
                setIsLoading(false)
                setVisible(false)
                resetFile()
            })
        } else {
            setIsLoading(false)
            getResourceModels()
            setIsSuccess(true)
            setNotifyColor("border-green-600")
            setNotifyText('Ресурсные модели успешно загружена')
            setNotifyData([])
            setVisibleNotify(true)
            setVisible(false)
            resetFile()
        }
    }

    const startPostFiles = () => {
        setIsLoading(true)
        let filesList = [...files]
        postFiles(filesList)
    }

    return (
        <div>
            {
                isLoading
                    ? (<div className="mb-6 font-bold text-xl">Идёт загрузка...</div>)
                    : ""
            }
            <div className="mb-5 text-lg font-bold mt-[-10px]">{title}</div>
            <div style={{width: 500}}>
                <div className="mb-6">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                           htmlFor="file_input">Выберите Excel-файл ресурсной модели</label>
                    <input
                        onChange={prepareFile}
                        multiple={true}
                        accept=".xlsx"
                        className="block w-full mb-5 text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                        id="file_input" name="rmFile" type="file" ref={ref}/>
                </div>

                <div className="flex justify-between">
                    <div></div>
                    <div>
                        <button
                            type="submit"
                            onClick={startPostFiles}
                            disabled={isLoading}
                            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        >Загрузить
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UploadRmFile;