import {httpRequest} from "./HttpService";

export default class UserService {

    static async createUser(body) {
        try {
            const response = await httpRequest({
                method: "POST",
                url: "/users/registration",
                data: body
            })

            return response.data
        } catch (e) {
            console.log(e)
        }
    }

    static async login(body)
    {
        try {
            const response = await httpRequest({
                method: "POST",
                url: "/auth/login",
                data: body
            })

            return response
        } catch (e) {
            console.log(e)
        }
    }

    static async postMe()
    {
        try {
            const response = await httpRequest({
                method: "POST",
                url: "/auth/me",
            })

            return response
        } catch (e) {
            console.log(e)
        }
    }

    static async logout()
    {
        try {
            const response = await httpRequest({
                method: "POST",
                url: "/auth/logout",
            })

            return response
        } catch (e) {
            console.log(e)
        }
    }

}