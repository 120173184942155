import React, {useState} from 'react';
import CatalogList from "./CatalogList";
import MainModal from "../modals/MainModal";
import MainButton from "../buttons/MainButton";
import UploadBcFile from "../modal-components/UploadBcFile";
import DownloadFileService from "../../API/DownloadFileService";
import DropDownButton from "../buttons/DropDownButton";
import Toast from "../toast/Toast";

const BcCatalog = () => {
    // GET: /catalogs/bc - получение списка справочников
    const catalogData = {id: 5, title: "Бюллетень Цен", code: "BC"}
    const [visible, setVisible] = useState(false)
    const [visibleNotify, setVisibleNotify] = useState(false)
    const [notifyText, setNotifyText] = useState('')
    const [notifyColor, setNotifyColor] = useState('border-green-600')
    const [notifyData, setNotifyData] = useState([])
    const [isSuccess, setIsSuccess] = useState(null)

    const [dropdownTitle, setDropdownTitle] = useState("Экспорт БЦ")
    const [isDisabledDropdown, setIsDisabledDropdown] = useState(false)

    const downloadBc = (withFactories) => {
        const download = DownloadFileService.downloadBcList(withFactories)
        download.then((value) => {
            // показать уведомление об успешном скачивании
            setDropdownTitle("Экспорт БЦ")
            setIsDisabledDropdown(false)
        })
    }

    const downloadBcWithoutFactories = () => {
        setDropdownTitle("Идёт скачивание БЦ...")
        setIsDisabledDropdown(true)
        downloadBc(false)
    }

    const downloadBcWithFactories = () => {
        setDropdownTitle("Идёт скачивание БЦ...")
        setIsDisabledDropdown(true)
        downloadBc(true)
    }

    return (
        <div className="pl-10 pt-6 pr-10 sm:ml-64">
            <MainModal visible={visible} setVisible={setVisible}>
                <UploadBcFile
                    title={"Загрузка Бюллетеня Цен"}
                    setVisible={setVisible}
                    setVisibleNotify={setVisibleNotify}
                    setNotifyText={setNotifyText}
                    setNotifyColor={setNotifyColor}
                    setNotifyData={setNotifyData}
                    setIsSuccess={setIsSuccess}
                />
            </MainModal>

            {/*<BcCatalogList/>*/}
            <CatalogList
                catalogData={catalogData}
                isModal={false}
                isRegions={true}
                importButton={<MainButton onClick={() => setVisible(true)}>Импорт БЦ</MainButton>}
                exportButton={<DropDownButton title={dropdownTitle} disabledButton={isDisabledDropdown} item1Title={"Только цены"} item2Title={"Цены и заводы"} click1Item={downloadBcWithoutFactories} click2Item={downloadBcWithFactories}/>} //<MainButton onClick={() => downloadBc()}>Экспорт БЦ</MainButton>
            />
            <Toast
                text={notifyText}
                notifyColor={notifyColor}
                dataArray={notifyData}
                isSuccess={isSuccess}
                setIsSuccess={setIsSuccess}
                isVisible={visibleNotify}
                setVisible={setVisibleNotify}
            />
        </div>
    );
};

export default BcCatalog;