import React from 'react';

const ArrowDown = () => {
    return (
        <div className="cursor-pointer hover:fill-amber-700">
            <svg width="10" height="7" viewBox="0 0 10 7" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 0V2L5 7L0 2V0L5 5L10 0Z" fillOpacity="1"/>
            </svg>
        </div>
    );
};

export default ArrowDown;