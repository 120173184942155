import React from 'react';
import ResourceModelEditable from "./ResourceModelEditable";
import ResourceModel from "./ResourceModel";

const ResourceModelPricesTable = ({resourceModelsPricesList, setPricesCount}) => {

    let counter = 0

    return (
        <div className="mt-0">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                    <th scope="col" className="px-1 py-3 w-1 text-center border border-[#343A40]">
                        №
                    </th>
                    <th scope="col" className="px-3 py-3 w-[150px] text-center border border-[#343A40]">
                        Код ресурса
                    </th>
                    <th scope="col" className="px-3 py-3 text-center border border-[#343A40]">
                        Наименование расценки
                    </th>
                    <th scope="col" className="px-3 py-3 text-center border border-[#343A40]">
                        Ед. измерения
                    </th>
                    <th scope="col" className="px-3 py-3 text-center border border-[#343A40]">
                        Количество
                    </th>
                    <th scope="col" className="px-3 py-3 text-center border border-[#343A40]">
                        Цена
                    </th>
                    <th scope="col" className="px-3 py-3 text-center border border-[#343A40]">
                        Стоимость
                    </th>
                </tr>
                </thead>
                <tbody>

                {resourceModelsPricesList.map((rItem) => {
                    return (
                        rItem.data.map((price) => {
                            if (price.id === 0) {
                                return (
                                    <tr>
                                        <td className="h-8 pl-2 bg-white border border-[#343A40] text-black font-bold dark:bg-gray-800 dark:border-gray-700"
                                            colSpan={7} key={price.name}>{price.name}</td>
                                    </tr>
                                )
                            }
                            counter++
                            return <ResourceModelEditable data={price} key={price.id} index={counter}/>
                        })
                    )
                })}
                </tbody>
            </table>
        </div>
    );
};

export default ResourceModelPricesTable;