import {httpRequest} from "./HttpService";

export default class CatalogService {

    // Получить все типы оборудования из классификатора
    static async getAll() {
        try {
            const response = await httpRequest({
                method: "GET",
                url: "/catalogs"
            })

            return response.data
        } catch (e) {
            console.log(e)
        }
    }

    // Получить список оборудования по одному типу
    // id: int
    static async getCatalog(id) {
        try {
            const  response = await httpRequest({
                method: "GET",
                url: `/catalogs/${id}`
            })

            return response.data
        } catch (e) {
            console.log(e)
        }
    }

    // Получить список оборудования по одному типу
    // id: int
    static async getCatalogByCode(code) {
        try {
            const  response = await httpRequest({
                method: "GET",
                url: `/catalogs/${code}/by-code`
            })

            return response.data
        } catch (e) {
            console.log(e)
        }
    }

    static async getCatalogChildren(id, childId) {
        try {
            const  response = await httpRequest({
                method: "GET",
                url: `/catalogs/${id}/children/${childId}`
            })

            return response.data
        } catch (e) {
            console.log(e)
        }
    }
}