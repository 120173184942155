import {httpRequest} from "./HttpService";

export default class UncsService {

    // Получить все заполненные показатели УНЦ
    static async getAll() {
        try {
            const response = await httpRequest({
                method: "GET",
                url: "/uncs"
            })

            return response.data
        } catch (e) {
            console.log(e)
        }
    }

    // Получить все РМ для выбранного УНЦ
    // id: int
    static async getRmByUnc(id) {
        try {
            const response = await httpRequest({
                method: "GET",
                url: `/uncs/${id}/resource-models`
            })

            return response.data
        } catch (e) {
            console.log(e)
        }
    }

    // Получить все виды УНЦ
    static async getUncListAll() {
        try {
            const response = await httpRequest({
                method: "GET",
                url: "/unc-list"
            })

            return response.data
        } catch (e) {
            console.log(e)
        }
    }

    // Получить один УНЦ
    // id: int
    static async getUnc(id) {
        try {
            const  response = await httpRequest({
                method: "GET",
                url: `/uncs/${id}`
            })

            return response.data
        } catch (e) {
            console.log(e)
        }
    }

    static async getUncCount() {
        try {
            const  response = await httpRequest({
                method: "GET",
                url: `/uncs-count`
            })

            return response.data
        } catch (e) {
            console.log(e)
        }
    }

    // Создать новый УНЦ
    // body: object
    static async postUnc(body) {
        try {
            const response = await httpRequest({
                method: "POST",
                url: "/uncs",
                data: body
            })

            return response.data
        } catch (e) {
            console.log(e)
        }
    }

    // Добавить одну или несколько РМ в УНЦ
    // uncId: int, rmIds: array
    static async postUncRM(uncId, rmIds) {
        try {
            const response = await httpRequest({
                method: "POST",
                url: `/uncs/${uncId}/resource-models`,
                data: {
                    rmIds
                }
            })

            return response.data
        } catch (e) {
            console.log(e)
        }
    }

    // Присвоить УНЦ регион
    // body: object
    static async postUncRegion(uncId, regionId) {
        try {
            const response = await httpRequest({
                method: "POST",
                url: `/uncs/${uncId}/set-region`,
                data: {
                    regionId
                }
            })

            return response.data
        } catch (e) {
            console.log(e)
        }
    }
}