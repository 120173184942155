import React from 'react';
import CatalogHeader from "./CatalogHeader";
import FactoriesList from "./FactoriesList";

const FactoriesCatalog = () => {
    return (
        <div className="pl-10 pt-6 pr-10 sm:ml-64">
            <CatalogHeader catalogTitle={"Заводы"}/>
            <FactoriesList/>
        </div>
    );
};

export default FactoriesCatalog;