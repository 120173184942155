import React, {useState} from 'react';
import ProfilePicture from '../../images/profile-picture.jpg';

const Avatar = (props) => {

    const [isDropdown, setIsDropdown] = useState(false)

    return (
        <li className="flex justify-between items-center mb-6">
            <img id="avatarButton" data-dropdown-toggle="userDropdown"
                 onClick={() => setIsDropdown(!isDropdown)}
                 data-dropdown-placement="bottom-start"
                 className="w-10 h-10 rounded-full cursor-pointer border-2 border-white"
                 src={ProfilePicture} alt="User dropdown"></img>
            <div
                className={isDropdown ? "z-50 text-base list-none bg-white divide-y divide-gray-100 rounded shadow dark:bg-gray-700 dark:divide-gray-600" : "hidden"}
                id="dropdown-user">
                {/*<div className="px-4 py-3" role="none">*/}
                {/*    <p className="text-sm font-medium text-gray-900 truncate dark:text-gray-300" role="none">*/}
                {/*        neil.sims@flowbite.com*/}
                {/*    </p>*/}
                {/*</div>*/}
                <ul className="py-1" role="none">
                    <li>
                        <div
                           onClick={() => props.logout()}
                           className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer"
                           role="menuitem">Выход</div>
                    </li>
                </ul>
            </div>
            <div className="text-white ml-4">{props.username}</div>

            <div
                className="ml-2"
                style={{position: 'relative'}}
            >
                <svg className="w-5 h-5 fill-white cursor-pointer" id="Layer_1" data-name="Layer 1"
                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 122.83">
                    <path
                        d="M73.81,7.47A43.14,43.14,0,0,1,92.69,19.35a42.33,42.33,0,0,1,10.76,21.36l0,.28c.21,1.21.36,2.36.45,3.44.11,1.26.17,2.53.17,3.8h0V58.36c0,2.81,0,5.67.2,8.54a32.41,32.41,0,0,0,4.34,14.62A36.6,36.6,0,0,0,120,92.83a6.34,6.34,0,0,1,2.65,3.65,6.52,6.52,0,0,1-.08,3.56,6.62,6.62,0,0,1-1.91,3,6.33,6.33,0,0,1-4.25,1.57H82.27l0,.08h0c-4.14,24.2-37.61,24.13-41.65-.08H6.45A6.33,6.33,0,0,1,2,102.92a6.6,6.6,0,0,1-1.81-6.5A6.33,6.33,0,0,1,3,92.71c5.66-3.83,9.62-8,12.12-12.76s3.65-10.44,3.65-17.28V48.23c0-1.16.06-2.42.18-3.77s.29-2.52.51-3.76A42.89,42.89,0,0,1,49.39,7.41C54-2.47,69.2-2.49,73.81,7.47ZM87.71,24A36.34,36.34,0,0,0,70.38,13.57,3.42,3.42,0,0,1,68,11.22c-1.71-5.87-11-6-12.72-.05a3.43,3.43,0,0,1-2.48,2.38A36.1,36.1,0,0,0,26.15,41.9q-.28,1.58-.42,3.15c-.09,1-.13,2-.13,3.18V62.67c0,7.91-1.38,14.56-4.45,20.43-2.94,5.62-7.36,10.39-13.54,14.72H115.27A42.38,42.38,0,0,1,102.8,85,39.18,39.18,0,0,1,97.5,67.4c-.22-2.88-.21-6-.2-9V48.23h0c0-1.1,0-2.17-.13-3.22s-.21-2-.36-2.85l-.06-.27a35.62,35.62,0,0,0-9-17.9Z"/>
                </svg>
                <div
                    className="w-5 h-5 flex cursor-pointer items-center justify-center bg-red-600 rounded-full text-white text-[16px]"
                    style={{position: 'absolute', top: -10, right: -10}}>5
                </div>
            </div>
        </li>
    );
};

export default Avatar;