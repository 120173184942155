import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import UncResourceModelsTable from "./UncResourceModelsTable";
import MainButton from "../buttons/MainButton";
import MainModal from "../modals/MainModal";
import AddResourceModalToUnc from "./AddResourceModalToUnc";
import UncsService from "../../API/UncsService";
import RmService from "../../API/RmService";

const UncCard = () => {

    const [visible, setVisible] = useState(false)
    const [resourceModelsList, setResourceModelsList] = useState([])
    const router = useNavigate()
    const params = useParams()


    // GET: /uncs/:id - общие данные об УНЦ
    const [unc, setUnc] = useState({})
    const [uncResourceModelsData, setUncResourceModelsData] = useState([]);

    // Проставляет isChecked = true для ресурсных моделей этого УНЦ в массиве всех РМ
    const setCheckedRmIds = (allRmData, rmByUncData) => {
        let ids = []
        rmByUncData.map((item) => ids.push(item.id))

        return allRmData.map((item) => {
            if (ids.includes(item.id)) {
                return {...item, isChecked: true}
            }
            return {...item, isChecked: false}
        })
    }

    const getUnc = () => {
        const uncData = UncsService.getUnc(params.id)
        uncData.then((value) => {
            setUnc(value.data)
            getRmByUnc()
        })
    }

    // Получить массив РМ этой УНЦ
    const getRmByUnc = () => {
        const rmData = UncsService.getRmByUnc(params.id)
        rmData.then((value) => {
            setUncResourceModelsData(value.data)
            getRmAll(value.data)
        })
    }

    // Получить все РМ и проставить у добавленных в эту РМ галочку
    const getRmAll = (rmByUncData) => {
        const rmAll = RmService.getAll()
        rmAll.then((value) => {
            setResourceModelsList(setCheckedRmIds(value.data.data, rmByUncData))
        })
    }

    // Получить список ID выделенных РМ у этой УНЦ и отправитьна сервер
    const postResourceModelsToUnc = () => {
        let rmIds = []
        resourceModelsList.map((item) => {
            if (item.isChecked) {
                rmIds.push(item.id)
            }
        })

        // POST: /uncs/:id - body: [1,2,3]
        const rmByUncPost = UncsService.postUncRM(params.id, rmIds)
        rmByUncPost.then((value) => {
            setVisible(false)
            getUnc()
            getRmByUnc()
        })
    }

    useEffect(() => {
        getUnc()
    }, [])

    // GET: /uncs/:id/resource-models - ресурсные модели этого УНЦ
 
    const setOpenClosed = (index) => {
        let changedItem = uncResourceModelsData[index]
        changedItem.isOpen = !changedItem.isOpen
        setUncResourceModelsData([...uncResourceModelsData.slice(0, index), changedItem, ...uncResourceModelsData.slice(index + 1)])
    }

    const setCheckedUnchecked = (index) => {
        let changedItem = resourceModelsList[index]
        changedItem.isChecked = !changedItem.isChecked
        setResourceModelsList([...resourceModelsList.slice(0, index), changedItem, ...resourceModelsList.slice(index + 1)])
    }

    return (
        <div className="pl-10 pt-6 pr-10 mb-10 sm:ml-64">
            <MainModal visible={visible} setVisible={setVisible}>
                <AddResourceModalToUnc title={"Добавление ресурсной модели"} data={resourceModelsList} setModalVisible={setVisible} getRmByUnc={getRmByUnc} setCheckedUnchecked={setCheckedUnchecked} postResourceModelsToUnc={postResourceModelsToUnc} uncId={params.id}/>
            </MainModal>
            <div className="flex justify-start items-start">
                <div
                    className="flex justify-center items-center bg-gray-300 w-10 h-10 border rounded-full cursor-pointer"
                    onClick={() => router(`/uncs`)}
                >
                    <div>
                        <svg width="11" height="16" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 8L8 0L10.2 2.1L4.3 8L10.2 13.9L8 16L0 8Z" fill="#1C2E45" fillOpacity="1"/>
                        </svg>
                    </div>
                </div>
                <div className="ml-12 w-44">
                    <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
                        Идентификатор:
                    </div>
                    <div>
                        {unc.code}
                    </div>
                </div>
                <div className="ml-12 w-[450px]">
                    <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
                        Наименование:
                    </div>
                    <div>
                        {unc.title}
                    </div>
                </div>
                <div className="ml-12">
                    <div className="mr-2 text-sm font-normal text-gray-500 dark:text-gray-400">
                        Количество РМ:
                    </div>
                    <div className="">
                        {unc.rmCount}
                    </div>
                </div>
            </div>
            <div className="p-6 mt-10 border border-[#343A40] rounded-md shadow">
                <div className="flex justify-end">
                    <div>
                        <MainButton onClick={() => setVisible(true)}>+ Добавить РМ</MainButton>
                    </div>
                </div>
                <div>
                    <UncResourceModelsTable data={uncResourceModelsData} setData={setOpenClosed}/>
                </div>
                <div className="flex justify-between mt-6">
                    <div>
                        <MainButton>Выгрузить</MainButton>
                    </div>
                    <div>
                        <div className="flex justify-end">
                            <div className="mr-1 font-medium text-sm text-gray-700">Итого:</div>
                            <div className="text-sm font-medium">{unc.cost > 0 ? (unc.cost).toLocaleString('ru') : 0} руб.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UncCard;