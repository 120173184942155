import React, {useEffect, useState} from 'react';
import Card from "./Card";
import Header from "../Header";
import CreateModel from "../modal-components/CreateModel";
import CreateUnc from "../modal-components/CreateUnc";
import MainModal from "../modals/MainModal";
import MainButton from "../buttons/MainButton";
import UncCard from "./Card";

const UncsList = ({list, getUncList}) => {

    const [visible, setVisible] = useState(false);
    const AddUncButton = <MainButton>+ Добавить УНЦ</MainButton>

    return (
        <div className="p-4 sm:ml-64">
            <MainModal visible={visible} setVisible={setVisible}>
                <CreateUnc title={"Создание показателя УНЦ"} setVisible={setVisible} getUncList={getUncList}/>
            </MainModal>
            <Header
                count={list.length}
                countText={"Всего показателей УНЦ:"}
                firstButtonText={"+ Добавить УНЦ"}
                setVisibleFirstButton={setVisible}
            />
            <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-4 mb-4">
                {list.map((item) =>
                    <UncCard data={item} key={item.id}/>
                )}
            </div>
        </div>
    );
};

export default UncsList;