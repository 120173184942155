import {httpRequest} from "./HttpService";

export default class RmService {

    // Получить все Ресурсные Модели
    static async getAll(limit = 5000, page = 1) {
        try {
            const response = await httpRequest({
                method: "GET",
                url: `/resource-models`,
                params: {
                    limit,
                    page
                }
            })

            return response
        } catch (e) {
            console.log(e)
        }
    }

    // Получить одну Ресурсную Модель
    // id: int
    static async getRm(id) {
        try {
            const response = await httpRequest({
                method: "GET",
                url: `/resource-models/${id}`
            })

            return response.data
        } catch (e) {
            console.log(e)
        }
    }

    static async getRmCount() {
        try {
            const response = await httpRequest({
                method: "GET",
                url: `/resource-models-count`
            })

            return response.data
        } catch (e) {
            console.log(e)
        }
    }

    static async getRmRecount() {
        const response = await httpRequest({
            method: "GET",
            url: `/resource-models-recount`
        })

        return response.data
    }

    static async setRmZeroExtFour() {
        try {
            const response = await httpRequest({
                method: "POST",
                url: `/resource-models-zero-ext`
            })

            return response.data
        } catch (e) {
            console.log(e)
        }
    }

    // Получить расценки по Ресурсной Модели
    // id: int
    static async getPricesByRm(id) {
        try {
            const response = await httpRequest({
                method: "GET",
                url: `/resource-models/${id}/prices`
            })

            return response.data
        } catch (e) {
            console.log(e)
        }
    }

    static async getRecountStatus() {
        try {
            const response = await httpRequest({
                method: "GET",
                url: `/resource-models-recount-status`
            })

            return response.data
        } catch (e) {
            console.log(e)
        }
    }

    // Создать новую Ресурсную Модель
    // body: object
    static async postRm(body) {
        try {
            const response = await httpRequest({
                method: "POST",
                url: "/resource-models",
                data: body
            })

            return response.data
        } catch (e) {
            console.log(e)
        }
    }

    // Отправить Excel-файл Ресурсной Модели
    // file: ???
    static async postExcelRM(file) {
        const formData = new FormData();
        formData.append('file', file);

        const response = await httpRequest({
            method: "POST",
            url: `/resource-models/import`,
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
            data: formData
        })

        return response.data
    }

    static async deleteRm(id) {
        const response = await httpRequest({
            method: "DELETE",
            url: `/resource-models/${id}`,
        })

        return response.data
    }
}