import React from 'react';
import checkIcon from '../../images/check.png'

const CheckIcon = () => {
    return (
        <div className="flex justify-center items-center">
            <img
                className="block w-[16px] h-[16px] cursor-pointer"
                src={checkIcon} alt=""/>
        </div>
    );
};

export default CheckIcon;