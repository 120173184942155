import {httpRequest} from "./HttpService";

export default class BcService {

    // Получить все типы оборудования из классификатора
    static async getAll() {
        try {
            const response = await httpRequest({
                method: "GET",
                url: "/bc"
            })

            return response.data
        } catch (e) {
            console.log(e)
        }
    }

    // Получить список оборудования по одному типу
    // id: int
    static async getBc(id, param) {
        try {
            const response = await httpRequest({
                method: "GET",
                url: `/bc/${id}?param=${param}`
            })

            return response.data
        } catch (e) {
            console.log(e)
        }
    }

    // Отправить Excel-файл Бюллетеня Цен
    // file: ???, factoryId: int
    static async postExcelBc(file, factoryId) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('factoryId', factoryId);

        const response = await httpRequest({
            method: "POST",
            url: `/bc/import`,
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
            data: formData
        })

        return response.data
    }
}