import React, {useEffect} from 'react';
import cl from './Toast.module.css';

const Toast = ({text, notifyColor, dataArray, isSuccess, isVisible, setVisible}) => {

    useEffect(() => {
        if (isVisible) {
            setTimeout(function () {
                setVisible(false)
            }, 5000);
        }
    }, [])

    const classes = " flex items-center w-full  p-4 mb-4 ml-10 text-gray-500 border-2 rounded-lg shadow bg-white " + notifyColor

    return (
        <div className={isVisible ? cl.toastMain + classes + " " + cl.show : cl.toastMain + classes}
             style={{width: 500, position: 'fixed', right: 30, bottom: 30, zIndex: 100000}}>
            <div
                className={!isSuccess ? "inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-600 bg-red-100 rounded-lg dark:bg-red-800 dark:text-red-200" : "inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200"}>
                {
                    isSuccess
                        ? (
                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd"
                                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                      clipRule="evenodd"></path>
                            </svg>
                        )
                        : (
                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd"
                                      d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                                      clipRule="evenodd"></path>
                            </svg>
                        )
                }

                <span className="sr-only">Check icon</span>
            </div>
            <div className="ml-3 text-sm font-normal">
                <div className="font-medium">
                    {text}
                </div>
                <div>
                    {
                        dataArray?.length ?
                            dataArray.map((item, index) => {
                                return (<div className={isSuccess ? "mt-1 text-black-600" : "mt-1 text-red-600"} key={index}>{item}</div>)
                            }) : ""
                    }
                </div>
            </div>
            <button type="button"
                    onClick={() => setVisible(false)}
                    className="ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
                    data-dismiss-target="#toast-success" aria-label="Close">
                <span className="sr-only">Close</span>
                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"></path>
                </svg>
            </button>
        </div>
    );
};

export default Toast;