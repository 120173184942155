import React from 'react';

const CatalogHeader = ({catalogTitle}) => {
    return (
        <div>
            <div className="flex justify-between">
                <div className="flex">
                    <div className="mr-2 font-bold  dark:text-gray-400">
                        Справочник:
                    </div>
                    <div>{catalogTitle}</div>
                </div>
            </div>
        </div>
    );
};

export default CatalogHeader;