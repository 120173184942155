import React, {useState} from 'react';
import CatalogPriceTable from "./CatalogPriceTable";
import SecondNodeItem from "./SecondNodeItem";
import CatalogService from "../../API/CatalogService";

const FirstNodeItem = ({data, index, setData, catalogData, checkedIds, setCheckedIds, isModal}) => {

    const classes = "text-sm ml-6 hover:underline cursor-pointer"

    const [catalogChildren, setCatalogChildren] = useState([])
    const [typeChildren, setTypeChildren] = useState('node')
    const [priceTitles, setPriceTitles] = useState({firstPriceTitle: '', secondPriceTitle: ''})

    const getChildren = (id, childId) => {
        const catalogChildrenData = CatalogService.getCatalogChildren(id, childId)
        catalogChildrenData.then((value) => {
            if (value.type === "price") {
                setPriceTitles({
                    firstPriceTitle: value.firstPriceTitle,
                    secondPriceTitle: value.secondPriceTitle,
                })
            }
            setTypeChildren(value.type)
            setCatalogChildren(value.data)
        })
    }

    const setOpenClosed = (index) => {
        let changedItem = catalogChildren[index]
        changedItem.isOpen = !changedItem.isOpen
        setCatalogChildren([...catalogChildren.slice(0, index), changedItem, ...catalogChildren.slice(index + 1)])
    }

    const nodeClick = () => {
        getChildren(catalogData.id, data.id)
        setData(index)
    }

    return (
        <div>
            <div className={data.isOpen ? classes + " font-bold underline" : classes} onClick={nodeClick}>{data.title}</div>
            <div className={data.isOpen ? "h-full ml-6 my-2" : "invisible h-0"}>
                {
                    typeChildren === "node"
                    ? catalogChildren.map((item, index) => <SecondNodeItem isModal={isModal} data={item} catalogData={catalogData} index={index} key={item.id}
                                                               setData={setOpenClosed} checkedIds={checkedIds}
                                                               setCheckedIds={setCheckedIds}/>)
                    : <CatalogPriceTable isModal={isModal} firstPriceTitle={priceTitles.firstPriceTitle} secondPriceTitle={priceTitles.secondPriceTitle} data={catalogChildren} checkedIds={checkedIds} setCheckedIds={setCheckedIds}/>
                }
            </div>
        </div>
    );
};

export default FirstNodeItem;