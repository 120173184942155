import React from 'react';

const CountBadge = (props) => {
    return (
        <div>
            <span className="inline-flex items-center justify-center px-2 ml-3 text-sm font-medium text-white bg-blue-700 rounded-full dark:bg-gray-700 dark:text-gray-300" style={{fontSize: "12px"}}>{props.count}</span>
        </div>
    );
};

export default CountBadge;