import React from 'react';
import CatalogHeader from "./CatalogHeader";
import CatalogList from "./CatalogList";

const GesnCatalog = () => {

    // GET: /catalogs/gesn - получение списка справочников
    const catalogsData = {id: 1, title: "ГЭСН - Затраты труда рабочих", code: "GESN"}

    return (
        <div className="pl-10 pt-6 pr-10 sm:ml-64">
            <CatalogList catalogData={catalogsData} isModal={false}/>
        </div>
    );
};

export default GesnCatalog;