import React, {useEffect, useState} from 'react';
import UncResourceModelsTableRow from "./UncResourceModelsTableRow";
import ResourceModel from "../resource-models/ResourceModel";
import {useParams} from "react-router-dom";
import RmService from "../../API/RmService";
import ResourceModelSummaryTable from "../resource-models/ResourceModelSummaryTable";

const UncResourceModelsTable = ({data, setData}) => {

    // GET: /resource-models/:id - получение информации о составе ресурсной модели

    return (
        <div className="mt-4">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                    <th scope="col" className="px-6 py-3 text-center border border-[#343A40]">
                        №
                    </th>
                    <th scope="col" className="px-6 py-3 w-[150px] text-center border border-[#343A40]">
                        Код РМ
                    </th>
                    <th scope="col" className="px-6 py-3 text-center border border-[#343A40]">
                        Наименование ресурсной модели
                    </th>
                    <th scope="col" className="px-6 py-3 text-center border border-[#343A40]">
                        Ед. измерения
                    </th>
                    <th scope="col" className="px-6 py-3 text-center border border-[#343A40]">
                        Количество
                    </th>
                    <th scope="col" className="px-6 py-3 text-center border border-[#343A40]">
                        Цена
                    </th>
                    <th scope="col" className="px-6 py-3 text-center border border-[#343A40]">
                        Стоимость
                    </th>
                </tr>
                </thead>

                {data.map((item, index) => {
                    if (item.isOpen) {
                        let counter = 0

                        return (
                            <tbody>
                            <UncResourceModelsTableRow data={item} index={index} key={item.id} setData={setData}/>
                            {item.prices.map((rItem) => {
                                return (
                                    rItem.data.map((price, index) => {
                                        if (price.id === 0) {
                                            return (
                                                <tr>
                                                    <td className="h-8 pl-2 bg-white border border-[#343A40] text-black font-bold dark:bg-gray-800 dark:border-gray-700"
                                                        colSpan={7} key={price.name}>{price.name}</td>
                                                </tr>
                                            )
                                        }
                                        counter++
                                        return <ResourceModel data={price} key={price.id} index={counter}/>
                                    })
                                )
                            })}
                            </tbody>
                        )
                    }

                    return (
                        <tbody>
                        <UncResourceModelsTableRow data={item} index={index} key={item.id} setData={setData}/>
                        </tbody>
                    )
                })}

            </table>
        </div>
    );
};

export default UncResourceModelsTable;