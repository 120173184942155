import React from 'react';
import CountBadge from "./CountBadge";
import {NavLink} from "react-router-dom";

const MenuItem = (props) => {

    const classes = "flex justify-between items-center p-2 font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-700  dark:hover:bg-gray-700"

    return (
        <li className="w-[100%]">
            <NavLink to={props.link}
                     className={({isActive, isPending}) => isActive ? classes + " border-2" : classes}>
                <div className="flex items-center">{props.icon}
                    <span className="ml-3 text-white text-sm">{props.title}</span>
                </div>
                <CountBadge count={props.count}/>
            </NavLink>
        </li>
    );
};

export default MenuItem;