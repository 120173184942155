import React from 'react';

const MarketAnalysis = () => {
    return (
        <div className="pl-10 pt-6 pr-10 mb-10 sm:ml-64">

        </div>
    );
};

export default MarketAnalysis;