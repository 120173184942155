import React from 'react';
import DownloadIcon from "../icons/DownloadIcon";
import DownloadFileService from "../../API/DownloadFileService";

const FactoryBcStatus = ({data}) => {

    const downloadFile = (file) => {
        const download = DownloadFileService.downloadFile(file)
        download.then((value) => {
            // показать уведомление об успешном скачивании
        })
    }

    return (
        <div className="w-full flex justify-start items-center">
            {
                data
                    ? (<div className="flex justify-between items-center w-full mr-1">
                        <div className="flex items-center w-full">
                            <div className="mr-2 h-3 w-3 rounded-full bg-green-500"></div>
                            <div>Загружен</div>
                        </div>
                        <div onClick={() => downloadFile(data)}>
                            <DownloadIcon/>
                        </div>
                    </div>)
                    : (<div className="flex items-center">
                        <div className="mr-2 h-3 w-3 rounded-full bg-orange-500"></div>
                        <div>Не загружен</div>
                    </div>)
            }
        </div>
    );
};

export default FactoryBcStatus;