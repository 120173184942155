import React, {useState} from 'react';
import UserService from "../API/UserService";
import {useNavigate} from "react-router-dom";

const Registration = () => {

    const router = useNavigate()
    const [email, setEmail] = useState('')
    const [firstName, setFirstName] = useState('')
    const [secondName, setSecondName] = useState('')
    const [password, setPassword] = useState('')
    const [user, setUser] = useState({})

    const createUser = (event) => {
        event.preventDefault()

        const postUser = UserService.createUser({
            email,
            firstName,
            secondName,
            password
        })

        postUser.then((value) => {
            router(`/login`)
        })
    }

    return (
        <div className="flex justify-center items-center">

            <form className="mt-4 p-6 border">
                <div className="mb-6 text-center text-2xl font-bold">Регистрация</div>
                <div className="mb-6">
                    <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Введите
                        email</label>
                    <input type="email" id="email"
                           value={email}
                           onChange={event => setEmail(event.target.value)}
                           className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                           placeholder="" required/>
                </div>
                <div className="mb-6">
                    <label htmlFor="first_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Введите фамилию</label>
                    <input type="text" id="first_name"
                           value={firstName}
                           onChange={event => setFirstName(event.target.value)}
                           className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                           required/>
                </div>
                <div className="mb-6">
                    <label htmlFor="second_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Введите имя</label>
                    <input type="text" id="second_name"
                           value={secondName}
                           onChange={event => setSecondName(event.target.value)}
                           className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                           required/>
                </div>
                <div className="mb-6">
                    <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Введите пароль</label>
                    <input type="password" id="password"
                           value={password}
                           onChange={event => setPassword(event.target.value)}
                           className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                           required/>
                </div>
                {/*<div className="mb-6">*/}
                {/*    <label htmlFor="repeat-password"*/}
                {/*           className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Повторите пароль</label>*/}
                {/*    <input type="password" id="repeat-password"*/}
                {/*           className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"*/}
                {/*           required/>*/}
                {/*</div>*/}
                <button type="submit"
                        onClick={event => createUser(event)}
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Зарегистрироваться
                </button>
            </form>

        </div>
    );
};

export default Registration;