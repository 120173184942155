import {httpRequest} from "./HttpService";

export default class RegionService {

    // Получить все регионы
    static async getAll() {
        try {
            const response = await httpRequest({
                method: "GET",
                url: `/regions`
            })

            return response.data
        } catch (e) {
            console.log(e)
        }
    }
}