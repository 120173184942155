import React from 'react';

const MainButton = ({children, onClick, icon, disabled, width, colorStyle, hoverColorStyle}) => {

    const classes = "text-white font-medium rounded-lg text-sm px-5 py-2 focus:outline-none"
    const colorStyleString = colorStyle ? colorStyle : "bg-blue-700"
    const hoverColorStyleString = hoverColorStyle ? "hover:" + hoverColorStyle : "hover:bg-blue-800"

    return (
        <button
            type="button"
            onClick={onClick}
            disabled={disabled}
            data-modal-target="defaultModal" data-modal-toggle="defaultModal"
            className={disabled ? classes + " bg-gray-500" : classes + " " + colorStyleString + " " + hoverColorStyleString + " focus:ring-4 focus:ring-blue-300"}
            style={width ? {width} : {}}
        >
            {icon}
            {children}
        </button>
    );
};

export default MainButton;