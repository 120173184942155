import React, {useEffect, useState} from 'react';
import ResourceModelsList from "../components/resource-models/ResourceModelsList";
import RmService from "../API/RmService";
import {getPagesCount} from "../utils/pages";

const ResourceModels = () => {

    // GET: /resource-models?limit=20&offset=0 - получение всех ресурсных моделей с динамической подгрузкой
    const [resourceModelsList, setResourceModels] = useState([])
    const [totalPages, setTotalPages] = useState(0)
    const [totalRmCount, setTotalRmCount] = useState(0)
    const [limit, setLimit] = useState(50)
    const [page, setPage] = useState(1)
    const [fetching, setFetching] = useState(true)

    const scrollHandler = (e) => {
        if (e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) < 100) {
            setFetching(true)
        }
    }

    const getResourceModels = () => {
        const rmList = RmService.getAll(limit, page)
        rmList.then((response) => {
            const totalCount = response.headers['x-total-count']
            setTotalRmCount(totalCount)
            setTotalPages(getPagesCount(totalCount, limit))
            setResourceModels([...resourceModelsList, ...response.data.data])
            setPage(page + 1)
        }).finally(() => {
            setFetching(false)
        })
    }

    useEffect(() => {
        if (fetching) {
            getResourceModels()
        }
    }, [fetching])

    useEffect(() => {
        document.addEventListener('scroll', scrollHandler)

        return () => {
            document.removeEventListener('scroll', scrollHandler)
        }
    }, [])

    return (
        <div>
            <ResourceModelsList list={resourceModelsList} rmCount={totalRmCount} getResourceModels={getResourceModels}/>
        </div>
    );
};

export default ResourceModels;