import React, {useEffect, useState} from 'react';
import ResourceModelsTableRowForUnc from "./ResourceModelsTableRowForUnc";
import PlusIcon from "../icons/PlusIcon";

const ResourceModalsTableForUnc = ({data, setCheckedUnchecked}) => {

    return (
        <div className="overflow-y-auto">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                    <th scope="col" className="px-6 py-3 text-center border">
                        №
                    </th>
                    <th scope="col" className="px-6 py-3 text-center border">
                        Идентификатор РМ
                    </th>
                    <th scope="col" className="px-6 py-3 text-center border">
                        Наименование ресурсной модели
                    </th>
                    <th scope="col" className="px-6 py-3 text-center border">
                        Стоимость
                    </th>
                    <th scope="col" className="px-4 py-1 text-center border">
                        <PlusIcon/>
                    </th>
                </tr>
                </thead>
                <tbody>
                {data.map((item, index) =>
                    <ResourceModelsTableRowForUnc data={item} index={index} key={item.id} setCheckedUnchecked={setCheckedUnchecked}/>
                )}
                </tbody>
            </table>
        </div>
    );
};

export default ResourceModalsTableForUnc;