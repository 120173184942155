import React, {useState} from 'react';
import CheckIcon from "../icons/CheckIcon";
import PlusIcon from "../icons/PlusIcon";
import ToolTip from "./tooltip/ToolTip";
import MultiPriceIcon from "../icons/MultiPriceIcon";
import RmPriceIcon from "../icons/RmPriceIcon";

const CatalogPriceTableRowBc = ({data, index, checkedIds, setCheckedIds, isModal}) => {

    const classes = "bg-white border-b dark:bg-gray-800 dark:border-gray-700"

    const [isChecked, setChecked] = useState(false)
    const [isVisible, setVisible] = useState(false)
    const [clickCoords, setClickCoords] = useState({})

    const setCheckedItems = () => {
        setChecked(!isChecked)
        if (isChecked) {
            setCheckedIds(checkedIds.filter(item => item !== data.id))
        } else {
            setCheckedIds([...checkedIds, data.id])
        }
    }

    return (
        <tr className={isChecked ? classes + " text-gray-400" : " text-gray-900"}>
            {
                Object.keys(data).map((item) => (item !== "id" && item !== "prices") ? (item !== 'price' ? (
                        item === "code" ?
                            (<td key={item} className=" px-2 py-1 border border-gray-500 text-sm text-center">
                                <div className="flex justify-start items-center">
                                    <div>
                                        {data['code']['title']}
                                    </div>
                                    <div className="ml-2" title={data['code']['rm_list'].join(" \r\n")}>
                                        {data['code']['rm_list'].length > 0 ? <RmPriceIcon/> : ""}
                                    </div>
                                </div>

                            </td>) :
                            (<td key={item} className="px-2 py-1 border border-gray-500 text-sm text-center">
                                {data[item]}
                            </td>)
                    ) : (
                        <td key={item} className="px-2 py-1 border border-gray-500 text-sm text-right"
                            onClick={(event) => {
                                setClickCoords({clientX: event.clientX, clientY: event.clientY})
                                setVisible(!isVisible)
                            }
                            }>
                            {data['prices'].length > 1
                                ? (
                                    <div className="cursor-pointer">
                                        <ToolTip data={data} isVisible={isVisible} setVisible={setVisible}
                                                 clickCoords={clickCoords}/>
                                        <div className="flex justify-end items-center">
                                            <div className={isVisible ? "font-bold mr-4" : "mr-4"}>{(data[item]).toLocaleString('ru')}</div>
                                            <MultiPriceIcon/>
                                        </div>
                                    </div>
                                )
                                : (
                                    <div className="cursor-pointer">
                                        <ToolTip data={data} isVisible={isVisible} setVisible={setVisible}
                                                 clickCoords={clickCoords}/>
                                        <div className="flex justify-end items-center">
                                            <div className={isVisible ? "font-bold mr-4" : "mr-4"}>{(data[item] !== -1 ? (data[item]).toLocaleString('ru') : '')}</div>
                                        </div>
                                    </div>
                                ) }

                        </td>
                    )

                ) : "")
            }
            {
                isModal
                    ?
                    (
                        <td className="px-2 py-1 text-right border-r border-b border-gray-500 text-sm">
                            <div onClick={() => setCheckedItems()}>
                                {
                                    isChecked
                                        ? <CheckIcon/>
                                        : <PlusIcon/>
                                }
                            </div>
                        </td>
                    )
                    : ""
            }

        </tr>
    );
};

export default CatalogPriceTableRowBc;