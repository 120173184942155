import React, {useState} from 'react';

const ResourceModelEditable = ({data, index}) => {

    const quantityClasses = "underline cursor-pointer"
    const [isVisibleInput, setVisibleInput] = useState(false)
    const [quantity, setQuantity] = useState(data.quantity)
    const showInput = () => {
        setVisibleInput(true)
    }
    const hideInput = () => {
        // POST: /resource-models/:id/prices/:id - body:{"quantity": 459.78} - отправка нового количества расценки
        // GET: /resource-models/:id/prices - получение всех расценок этой ресурсной модели
        setVisibleInput(false)
    }
    const getQuantity = () => {
        if (isVisibleInput) {
            return (
                <input
                    autoFocus
                    className="block mb-2 pl-1 w-20 text-sm font-medium text-gray-900 dark:text-white border border-gray-700 rounded-md"
                    type="text"
                    value={quantity.toLocaleString('ru')}
                    onChange={event => setQuantity(event.target.value)}
                    onBlur={hideInput}
                />
            )
        } else {
            return (<div className={data.quantity === 0 ? quantityClasses + " font-bold text-orange-600" : quantityClasses} onClick={showInput}>
                {(data.quantity).toLocaleString('ru')}
            </div>)
        }
    }

    return (
        <tr className="bg-white border-b border-[#343A40] dark:bg-gray-800 dark:border-gray-700">
            <th scope="row"
                className="px-6 py-1 w-8 font-normal text-center text-gray-900 whitespace-nowrap dark:text-white border-l border-[#343A40]">

                {index}
            </th>
            <td className="px-6 py-1 text-center text-black">
                {data.code}
            </td>
            <td className="px-3 py-1 text-black">
                {data.name}
            </td>
            <td className="px-6 py-1 text-center text-black">
                {data.unit}
            </td>
            <td className="px-6 py-1 text-center text-black underline">
                {getQuantity()}
            </td>
            <td className="px-6 py-1 text-center text-black">
                {data.price}
            </td>
            <td className="px-2 py-1 text-right text-black border-r border-[#343A40]">
                <div className={data.cost > 0 ? "" : "font-bold text-orange-600"}>
                    {(data.cost).toLocaleString('ru')}
                </div>
            </td>
        </tr>
    );
};

export default ResourceModelEditable;