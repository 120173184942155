import React from 'react';

const RmPriceIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="13" cy="13" r="12.5" fill="#FF9119" stroke="#F5F5F5"/>
            <path d="M5.26514 17V9H8.26514C8.87972 9 9.39535 9.11458 9.81201 9.34375C10.2313 9.57292 10.5477 9.88802 10.7612 10.2891C10.9774 10.6875 11.0854 11.1406 11.0854 11.6484C11.0854 12.1615 10.9774 12.6172 10.7612 13.0156C10.5451 13.4141 10.2261 13.7279 9.8042 13.957C9.38232 14.1836 8.86279 14.2969 8.24561 14.2969H6.25732V13.1055H8.05029C8.40967 13.1055 8.70394 13.043 8.93311 12.918C9.16227 12.793 9.33154 12.6211 9.44092 12.4023C9.5529 12.1836 9.60889 11.9323 9.60889 11.6484C9.60889 11.3646 9.5529 11.1146 9.44092 10.8984C9.33154 10.6823 9.16097 10.5143 8.9292 10.3945C8.70003 10.2721 8.40446 10.2109 8.04248 10.2109H6.71436V17H5.26514ZM12.3442 9H14.1177L16.4927 14.7969H16.5864L18.9614 9H20.7349V17H19.3442V11.5039H19.27L17.0591 16.9766H16.02L13.8091 11.4922H13.7349V17H12.3442V9Z" fill="white"/>
        </svg>

    );
};

export default RmPriceIcon;