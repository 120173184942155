import {httpRequest} from "./HttpService";

export default class DownloadFileService {

    static async downloadFile(filePath) {
        try {
            const response = await httpRequest({
                method: "GET",
                url: `/download-file?file_path=${filePath}`,
                responseType: 'blob',
            })

            this.download(response)

            return response.data
        } catch (e) {
            console.log(e)
        }
    }

    static async downloadRmList() {
        try {
            const response = await httpRequest({
                method: "GET",
                url: `/download-rm-list`,
                responseType: 'blob',
            })

            this.download(response)

            return response.data
        } catch (e) {
            console.log(e)
        }
    }

    static async downloadRmPricesList() {
        try {
            const response = await httpRequest({
                method: "GET",
                url: `/download-rm-prices`,
                responseType: 'blob',
            })

            this.download(response)

            return response.data
        } catch (e) {
            console.log(e)
        }
    }

    static async downloadRmZip() {
        try {
            const response = await httpRequest({
                method: "GET",
                url: `/download-rm-zip`,
                responseType: 'blob',
            })

            this.download(response)

            return response.data
        } catch (e) {
            console.log(e)
        }
    }

    static async downloadBcList(withFactories) {
        try {
            const param = withFactories ? '?with_factories=yes' : ''
            const response = await httpRequest({
                method: "GET",
                url: `/download-bc-list${param}`,
                responseType: 'blob',
            })

            this.download(response)

            return response.data
        } catch (e) {
            console.log(e)
        }
    }

    static download(response) {
        const url = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        const disposition = response.request.getResponseHeader('Content-Disposition');

        let fileName = '';
        if (disposition && disposition.indexOf('attachment') !== -1) {
            fileName = disposition.split("''")[1]
            fileName = decodeURI(fileName)

            document.body.appendChild(link);
            link.href = url;
            link.setAttribute('download', fileName);
            link.click();
            link.remove();
        }
    }
}