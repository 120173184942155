import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import FactoryService from "../../API/FactoryService";
import MainButton from "../buttons/MainButton";
import FactoryClassifierRow from "./FactoryClassifierRow";
import Toast from "../toast/Toast";
import DownloadFileService from "../../API/DownloadFileService";

const FactoryCard = () => {

    const router = useNavigate()
    const params = useParams()

    const [visibleNotify, setVisibleNotify] = useState(false)
    const [notifyText, setNotifyText] = useState('')
    const [notifyColor, setNotifyColor] = useState('border-green-600')
    const [notifyData, setNotifyData] = useState([])
    const [isSuccess, setIsSuccess] = useState(null)

    const [factory, setFactory] = useState({id: 0, name: '', address: '', bcStatus: '', production_types: '', ceo_name: '', classifiers: []})

    useEffect(() => {
        getFactory(params.id)
    }, [])

    const getFactory = (id) => {
        const factoryData = FactoryService.getFactory(id)
        factoryData.then((value) => {
            setFactory(value.data)
        })
    }

    const downloadFile = (file) => {
        const download = DownloadFileService.downloadFile(file)
        download.then((value) => {
            // показать уведомление об успешном скачивании
        })
    }

    const deleteClassifier = (classifierId) => {
        const deleteClassifier = FactoryService.deleteClassifierFromFactory(factory.id, classifierId)
        deleteClassifier.then((value) => {
            setIsSuccess(true)
            setNotifyColor("border-green-600")
            setNotifyText(value.result)
            setNotifyData(value?.messages)
            setVisibleNotify(true)
            getFactory(params.id)
        }).catch((error) => {
            setIsSuccess(false)
            setNotifyColor("border-red-600")
            setNotifyText(error.response.data.result)
            setNotifyData(error.response.data?.messages)
            setVisibleNotify(true)
        })
    }

    return (
        <div className="pl-10 pt-6 pr-10 mb-10 sm:ml-64">
            <div className="flex justify-between items-center">
                <div className="flex justify-start items-start">
                    <div
                        className="flex justify-center items-center bg-gray-300 min-w-[40px] h-10 border rounded-full cursor-pointer"
                        onClick={() => router(`/factories`)}
                    >
                        <div>
                            <svg width="11" height="16" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 8L8 0L10.2 2.1L4.3 8L10.2 13.9L8 16L0 8Z" fill="#1C2E45" fillOpacity="1"/>
                            </svg>
                        </div>
                    </div>
                    <div className="ml-12">
                        <div className="text-sm font-normal text-gray-500">
                            Название завода:
                        </div>
                        <div>
                            {factory.name}
                        </div>
                    </div>
                    <div className="ml-12">
                        <div className="text-sm font-normal text-gray-500">
                            Типы продукции:
                        </div>
                        <div>{factory.production_types}</div>
                    </div>
                    <div className="ml-12">
                        <div className="text-sm font-normal text-gray-500">
                            ФИО ГД:
                        </div>
                        <div>{factory.ceo_name}</div>
                    </div>
                </div>

                <div className="flex justify-end ">
                    <div className="w-[120px]">
                        <MainButton onClick={() => downloadFile(factory.bcStatus)}>Скачать БЦ</MainButton>
                    </div>
                </div>
            </div>
            <div className="flex justify-start items-start mt-4">
                <div className="ml-0">
                    <div className="text-sm font-normal text-gray-500">
                        Адрес:
                    </div>
                    <div>
                        {factory.address}
                    </div>
                </div>

            </div>
            <table className="w-full text-sm text-left text-gray-500 mt-10">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                    <th scope="col" className="px-6 py-3 w-6 text-center border">
                        №
                    </th>
                    <th scope="col" className="px-6 py-3 w-4 text-center border">
                        Код вкладки
                    </th>
                    <th scope="col" className="px-6 py-3 text-center border">
                        Наименование оборудования
                    </th>
                    <th scope="col" className="px-6 py-3 text-center border">

                    </th>
                </tr>
                </thead>
                <tbody>
                {
                    factory.classifiers.map((item, index) => {
                        return (<FactoryClassifierRow data={item} index={index} deleteClassifier={deleteClassifier}/>)
                    })
                }
                </tbody>
            </table>
            <Toast
                text={notifyText}
                notifyColor={notifyColor}
                dataArray={notifyData}
                isSuccess={isSuccess}
                setIsSuccess={setIsSuccess}
                isVisible={visibleNotify}
                setVisible={setVisibleNotify}
            />
        </div>
    );
};

export default FactoryCard;