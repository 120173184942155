import React, {useEffect, useState} from 'react';
import PlusIcon from "../icons/PlusIcon";
import CheckIcon from "../icons/CheckIcon";

const ResourceModelsTableRowForUnc = ({data, index, setCheckedUnchecked}) => {

    const classes = "bg-white border-b dark:bg-gray-800 dark:border-gray-700"

    return (
        <tr className={data.isChecked ? classes + " text-gray-400" : " text-gray-900"}>
            <th scope="row"
                className="px-6 py-2 font-medium text-center whitespace-nowrap dark:text-white border">
                {index + 1}
            </th>
            <td className="px-6 py-2 border-r border-b">
                {data.code}
            </td>
            <td className="px-6 py-2 border-r border-b">
                {data.title}
            </td>
            <td className="px-6 py-2 text-right border-r border-b" >
                {(data.cost).toLocaleString('ru')}
            </td>
            <td className="px-2 py-2 text-right border-r border-b">
                <div onClick={() => setCheckedUnchecked(index)}>
                    {
                        data.isChecked
                        ? <CheckIcon/>
                        : <PlusIcon/>
                    }
                </div>
            </td>
        </tr>
    );
};

export default ResourceModelsTableRowForUnc;