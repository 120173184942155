import React, {useState} from 'react';
import CheckIcon from "../icons/CheckIcon";
import PlusIcon from "../icons/PlusIcon";

const CatalogPriceTableRow = ({data, index, checkedIds, setCheckedIds, isModal}) => {

    const classes = "bg-white border-b dark:bg-gray-800 dark:border-gray-700"

    const [isChecked, setChecked] = useState(false)

    const setCheckedItems = () => {
        setChecked(!isChecked)
        if (isChecked) {
            setCheckedIds(checkedIds.filter(item => item !== data.id))
        } else {
            setCheckedIds([...checkedIds, data.id])
        }
    }

    return (
        <tr className={isChecked ? classes + " text-gray-400" : " text-gray-900"}>
            <td className="px-2 py-1 text-center border border-gray-500 text-sm">
                {data.code}
            </td>
            <td className="px-2 py-1 border-r border-b border-gray-500 text-sm">
                {data.title}
            </td>
            <td className="px-2 py-1 text-center border-r border-b border-gray-500 text-sm">
                {data.unit}
            </td>
            <td className="px-2 py-1 text-center border-r border-b border-gray-500 text-sm" >
                {(data.firstPrice).toLocaleString('ru')}
            </td>
            <td className="px-2 py-1 text-center border-r border-b border-gray-500 text-sm" >
                {(data.secondPrice).toLocaleString('ru')}
            </td>
            {
                isModal
                ?
                    (
                        <td className="px-2 py-1 text-right border-r border-b border-gray-500 text-sm">
                            <div onClick={() => setCheckedItems()}>
                                {
                                    isChecked
                                        ? <CheckIcon/>
                                        : <PlusIcon/>
                                }
                            </div>
                        </td>
                    )
                : ""
            }

        </tr>
    );
};

export default CatalogPriceTableRow;