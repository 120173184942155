import React from 'react';
import CatalogHeader from "./CatalogHeader";
import CatalogList from "./CatalogList";

const FsscoCatalog = () => {
    // GET: /catalogs/fssco - получение списка справочников
    const catalogsData = {id: 3, title: "ФССЦо - Оборудование", code: "FSSCO"}

    return (
        <div className="pl-10 pt-6 pr-10 sm:ml-64">
            <CatalogList catalogData={catalogsData} isModal={false}/>
        </div>
    );
};

export default FsscoCatalog;