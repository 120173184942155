import React, {useEffect, useState} from "react";
import UncsList from "../components/uncs/UncsList";
import UncsService from "../API/UncsService";

function Uncs() {

    // GET: /uncs?limit=20&offset=0 - получение всех показателей УНЦ с динамической подгрузкой

    const [uncs, setUncs] = useState([]);
    const getUncList = () => {
        const uncsList = UncsService.getAll()
        uncsList.then((value) => setUncs(value.data))
    }

    useEffect(() => getUncList(), [])

    return (
        <div>
            <UncsList list={uncs} setUncs={setUncs} getUncList={getUncList}/>
        </div>
    );
}

export default Uncs;