import React, {useState} from 'react';

const DropDownButton = ({title, disabledButton, item1Title, item2Title, click1Item, click2Item}) => {

    const classes = "z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"
    const [isDropdown, setIsDropdown] = useState(false)

    const showHideDropdown = () => {
        setIsDropdown(!isDropdown)
    }

    return (
        <div>
            <button id="dropdownDefaultButton" data-dropdown-toggle="dropdown"
                    onBlur={() => setTimeout(() => setIsDropdown(false), 100)}
                    onClick={showHideDropdown}
                    disabled={disabledButton}
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center"
                    type="button">{title}
                <svg className="w-4 h-4 ml-2" aria-hidden="true" fill="none"
                     stroke="currentColor" viewBox="0 0 24 24"
                     xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                </svg>
            </button>

            <div id="dropdown"
                 style={{position: 'absolute'}}
                 className={isDropdown ? classes : classes + " hidden"}>
                <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
                    <li>
                        <span
                            onClick={() => {
                                setIsDropdown(false)
                                click1Item()
                            }}
                            className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer">{item1Title}</span>
                    </li>
                    <li>
                        <span
                            onClick={() => {
                                setIsDropdown(false)
                                click2Item()
                            }}
                            className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer">{item2Title}</span>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default DropDownButton;