import React, {useState} from 'react';
import Header from "../Header";
import ResourceModelsTable from "./ResourceModelsTable";
import CreateModel from "../modal-components/CreateModel";
import MainModal from "../modals/MainModal";
import UploadRmFile from "../modal-components/UploadRmFile";
import Toast from "../toast/Toast";

const ResourceModelsList = ({list, rmCount, getResourceModels}) => {

    const [visibleFirst, setVisibleFirst] = useState(false)
    const [visibleSecond, setVisibleSecond] = useState(false)
    const [visibleNotify, setVisibleNotify] = useState(false)
    const [notifyText, setNotifyText] = useState('')
    const [notifyColor, setNotifyColor] = useState('border-green-600')
    const [notifyData, setNotifyData] = useState([])
    const [isSuccess, setIsSuccess] = useState(null)

    return (
        <div className="p-4 sm:ml-64">
            <MainModal visible={visibleFirst} setVisible={setVisibleFirst}>
                <CreateModel title={"Создание ресурсной модели"}/>
            </MainModal>
            <MainModal visible={visibleSecond} setVisible={setVisibleSecond}>
                <UploadRmFile
                    title={"Загрузка ресурсной модели"}
                    setVisible={setVisibleSecond}
                    getResourceModels={getResourceModels}
                    setVisibleNotify={setVisibleNotify}
                    setNotifyText={setNotifyText}
                    setNotifyColor={setNotifyColor}
                    setNotifyData={setNotifyData}
                    setIsSuccess={setIsSuccess}
                />
            </MainModal>
            <Header
                count={rmCount}
                countText={"Всего ресурсных моделей:"}
                firstButtonText={"Добавить РМ"}
                secondButtonText={"Импорт РМ"}
                setVisibleFirstButton={setVisibleFirst}
                setVisibleSecondButton={setVisibleSecond}
            />
            <ResourceModelsTable data={list}/>
            <Toast
                text={notifyText}
                notifyColor={notifyColor}
                dataArray={notifyData}
                isSuccess={isSuccess}
                setIsSuccess={setIsSuccess}
                isVisible={visibleNotify}
                setVisible={setVisibleNotify}
            />
        </div>
    );
};

export default ResourceModelsList;