import React, {useEffect, useState} from "react";
import Sidebar from "./components/sidebar/Sidebar";
import {BrowserRouter} from "react-router-dom";
import AppRouter from "./components/router/AppRouter";
import {ToastContainer} from "react-toastify";
import {AuthContext} from './context/index';
import UncsService from "./API/UncsService";
import RmService from "./API/RmService";

function App() {

    const [user, setUser] = useState(null)
    const [isAuth, setIsAuth] = useState(false)


    useEffect(() => {
        if (localStorage.getItem('access_token')) {
            setIsAuth(true)
        }
    }, [])

    return (
        <AuthContext.Provider value={{
            isAuth,
            setIsAuth
        }}>
            <BrowserRouter>
                { isAuth
                    ? <Sidebar user={user}/>
                    : ""
                }
                <AppRouter/>
                <ToastContainer position='bottom-left' autoClose={10000} />
            </BrowserRouter>
        </AuthContext.Provider>
    );
}

export default App;
