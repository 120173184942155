import React, {useEffect, useState} from 'react';
import ResourceModalsTableForUnc from "../resource-models/ResourceModalsTableForUnc";
import MainButton from "../buttons/MainButton";
import RmService from "../../API/RmService";
import UncsService from "../../API/UncsService";

const AddResourceModalToUnc = ({title, data, setModalVisible, getRmByUnc, setCheckedUnchecked, postResourceModelsToUnc, uncId}) => {

    // GET: /resource-models?limit=20&offset=0 - получение всех ресурсных моделей с динамической подгрузкой

    return (
        <div>
            <div className="mb-5 text-lg font-bold mt-[-10px]">{title}</div>
            <input
                type="text"
                id="small-input"
                placeholder="Поиск..."
                className="block mb-4 w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            ></input>
            <div style={{minWidth: 850, maxWidth: 950, minHeight: 500, maxHeight: 550}}
                 className="border overflow-y-auto">
                <ResourceModalsTableForUnc data={data} setCheckedUnchecked={setCheckedUnchecked}/>
            </div>
            <div className="flex justify-end mt-3">
                <div>
                    <MainButton onClick={postResourceModelsToUnc}>Сохранить</MainButton>
                </div>
            </div>
        </div>
    );
};

export default AddResourceModalToUnc;