import React, {useContext, useEffect, useState} from 'react';
import Avatar from "./Avatar";
import Dropdown from "./Dropdown";
import DownloadIcon from "../icons/DownloadIcon";
import UNCIcon from "../icons/UNCIcon";
import RMIcon from "../icons/RMIcon";
import MenuItem from "./MenuItem";
import MAIcon from "../icons/MAIcon";
import UserService from "../../API/UserService";
import {AuthContext} from "../../context";
import {useNavigate} from "react-router-dom";
import UncsService from "../../API/UncsService";
import RmService from "../../API/RmService";
import DropdownExport from "./DropdownExport";
import DownloadFileService from "../../API/DownloadFileService";
import MainButton from "../buttons/MainButton";
import DropdownUtils from "./DropdownUtils";
import Toast from "../toast/Toast";

const Sidebar = () => {

    // GET: /catalogs - получение списка справочников
    const directoriesList = [
        {id: 1, title: "ГЭСН - Затраты труда рабочих", link: "/gesn"},
        {id: 2, title: "ФСЭМ - Машины и механизмы", link: "/fsem"},
        {id: 3, title: "ФССЦо - Оборудование", link: "/fssco"},
        {id: 4, title: "ФССЦм - Материалы", link: "/fsscm"},
        {id: 5, title: "Бюллетень Цен", link: "/bc"},
        {id: 6, title: "Заводы", link: "/factories"},
    ];

    // GET: /user - получение данных польхователя
    // const user = {
    //     id: 1,
    //     name: 'Иван Петров',
    //     roleId: 1
    // }

    const navigate = useNavigate()
    const {isAuth, setIsAuth} = useContext(AuthContext)
    const [user, setUser] = useState({})
    const [uncsCount, setUncsCount] = useState(0);
    const [resourceModelsCount, setResourceModelsCount] = useState(0);
    const [resourceModelsTitle, setResourceModelsTitle] = useState("Ресурсные модели")
    const [pricesFromRmTitle, setPricesFromRmTitle] = useState("Расценки БЦ из РМ")
    const [recountRmCount, setRecountRmCount] = useState(0)
    const [rmCount, setRmCount] = useState(0)
    const [recountBtnTitle, setRecountBtnTitle] = useState("Пересчитать РМ")

    const [visibleNotify, setVisibleNotify] = useState(false)
    const [notifyText, setNotifyText] = useState('')
    const [notifyColor, setNotifyColor] = useState('border-green-600')
    const [notifyData, setNotifyData] = useState([])
    const [isSuccess, setIsSuccess] = useState(null)

    const getStatistic = () => {
        const uncsCountData = UncsService.getUncCount()
        uncsCountData.then((value) => {
            setUncsCount(value.result)
        })

        const rmCountData = RmService.getRmCount()
        rmCountData.then((value) => {
            setResourceModelsCount(value.result)
        })
    }

    const downloadRmZip = () => {
        if (resourceModelsTitle === "Ресурсные модели") {
            setResourceModelsTitle("Идёт скачивание РМ...")
            const download = DownloadFileService.downloadRmZip()
            download.then((value) => {
                // показать уведомление об успешном скачивании
                setResourceModelsTitle("Ресурсные модели")
            }).catch((error) => {
                setResourceModelsTitle("Ресурсные модели")
            })
        }
    }

    const downloadRmPrices = () => {
        if (pricesFromRmTitle === "Расценки БЦ из РМ") {
            setPricesFromRmTitle("Идёт скачивание...")
            const download = DownloadFileService.downloadRmPricesList()
            download.then((value) => {
                setPricesFromRmTitle("Расценки БЦ из РМ")
            })
        }
    }

    const downloadUncZip = () => {
        // const download = DownloadFileService.downloadUncZip()
        // download.then((value) => {
        //     // показать уведомление об успешном скачивании
        // })
    }

    const recountRms = () => {
        const recount = RmService.getRmRecount()
        recount.then((value) => {
            setRecountBtnTitle("Идёт пересчёт РМ...")
            rmRecountStatus()
        }).catch((error) => {
            setIsSuccess(false)
            setNotifyColor("border-red-600")
            setNotifyText(error.response.data.result)
            setNotifyData(error.response.data?.messages)
            setVisibleNotify(true)
        })
    }

    const rmRecountStatus = () => {
        const recountStatus = RmService.getRecountStatus()
        recountStatus.then((value) => {
            if (value?.result?.left > 0) {
                setRecountBtnTitle("Идёт пересчёт РМ...")
                setRecountRmCount(value.result.left)
                setRmCount(value.result.count)
                setTimeout(rmRecountStatus, 2000);
            } else {
                setRecountRmCount(-1)
                setRecountBtnTitle("Пересчитать РМ")
            }
        })
    }

    const logout = () => {
        const userData = UserService.logout()
        userData.then((value) => {
            if (value.status === 200) {
                localStorage.removeItem('access_token')
                setIsAuth(false)
                setUser({})
                navigate('/login')
            }
        })
    }

    useEffect(() => {
        const userData = UserService.postMe()
        userData.then((value) => {
            if (value.status === 200) {
                setUser(value.data)
            }
        })

        getStatistic()
        rmRecountStatus()
    }, [])

    return (
        <div>
            <aside id="default-sidebar"
                   className="fixed top-0 left-0 z-40 w-64 h-screen transition-transform -translate-x-full sm:translate-x-0"
                   aria-label="Sidebar">
                <div className="h-full px-6 py-6 overflow-y-auto bg-[#343A40] dark:bg-gray-800">
                    <ul className="space-y-2 font-medium">
                        <Avatar username={user.name} logout={logout}/>
                        {recountRmCount > 0 ? (<div className="text-sm text-white text-center">{rmCount - recountRmCount} из {rmCount}</div>) : ""}
                        <MainButton onClick={recountRms} disabled={recountRmCount > 0} width={'100%'} colorStyle={"bg-orange-500"} hoverColorStyle={"bg-orange-600"}>{recountBtnTitle}</MainButton>
                        {/*<Search/>*/}
                        <MenuItem title={"Показатели УНЦ"} link={"/uncs"} icon={<UNCIcon/>} count={uncsCount}/>
                        <MenuItem title={"Ресурсные модели"} link={"/resource-models"} icon={<RMIcon/>} count={resourceModelsCount}/>
                        <MenuItem title={"Конъюнктурный анализ"} link={"/market-analysis"} icon={<MAIcon/>}/>
                        <Dropdown title={"Справочники"} linkList={directoriesList}/>
                        <DropdownExport
                            mainTitle={"Выгрузка"}
                            title1={"Показатели УНЦ"}
                            title2={resourceModelsTitle}
                            method1Item={downloadUncZip}
                            title3={pricesFromRmTitle}
                            method2Item={downloadRmZip}
                            method3Item={downloadRmPrices}
                            icon={<DownloadIcon color={"#ffffff"}/>}
                        />
                        <DropdownUtils
                            title={"Доп. функции"}
                            setVisibleNotify={setVisibleNotify}
                            setNotifyText={setNotifyText}
                            setNotifyColor={setNotifyColor}
                            setNotifyData={setNotifyData}
                            setIsSuccess={setIsSuccess}
                        />
                    </ul>
                </div>
            </aside>
            <Toast
                text={notifyText}
                notifyColor={notifyColor}
                dataArray={notifyData}
                isSuccess={isSuccess}
                setIsSuccess={setIsSuccess}
                isVisible={visibleNotify}
                setVisible={setVisibleNotify}
            />
        </div>
    );
};

export default Sidebar;