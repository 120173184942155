import axios from "axios";

const fetchHeaders = () => ({
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
    'Accept': 'application/json',
});

const baseURL = `${process.env.REACT_APP_BASE_HOST_URL}/api/v1`;

const httpInstance = axios.create({
    baseURL,
    headers: fetchHeaders(),
});

httpInstance.interceptors.request.use((request) => {
    if (localStorage.getItem('access_token')) {
        request.headers = {
            'X-Requested-With': 'XMLHttpRequest',
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
    }

    return request
}, (error) => {

})

httpInstance.interceptors.response.use((response) => {

    return response
}, (error) => {

    if (error.response.status === 401) {
        window.location.href = `${process.env.REACT_APP_BASE_HOST_URL}/login`
        return
    }
    return Promise.reject(error)
})

export const httpRequest = (request) => httpInstance({
    ...request
})