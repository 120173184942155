import {httpRequest} from "./HttpService";

export default class FactoryService {

    // Получить все заводы
    static async getAll() {
        try {
            const response = await httpRequest({
                method: "GET",
                url: `/factories`
            })

            return response.data
        } catch (e) {
            console.log(e)
        }
    }

    // Получить один завод
    static async getFactory(id) {
        try {
            const response = await httpRequest({
                method: "GET",
                url: `/factories/${id}`
            })

            return response.data
        } catch (e) {
            console.log(e)
        }
    }

    static async deleteClassifierFromFactory(factoryId, classifierId) {
        const response = await httpRequest({
            method: "DELETE",
            url: `/factories/${factoryId}/classifier/${classifierId}`
        })

        return response.data
    }
}