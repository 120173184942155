import React from 'react';
import GesnCatalog from "../components/catalogs/GesnCatalog";

const Gesn = () => {
    return (
        <div>
            <GesnCatalog/>
        </div>
    );
};

export default Gesn;