import React, {useContext, useEffect, useState} from 'react';
import {Route, Routes} from "react-router-dom";
import {routes, publicRoutes} from "./index";
import {AuthContext} from "../../context";

const AppRouter = () => {
    const {isAuth, setIsAuth} = useContext(AuthContext)

    // useEffect(() => {
    //     if (localStorage.getItem('access_token')) {
    //         setIsAuth(true)
    //     }
    // }, [])

    return (
        // isAuth
        //     ?
            <Routes>
                {routes.map((route, index) =>
                    <Route exact={route.exact} path={route.path} element={route.component} key={index}/>
                )}
                {publicRoutes.map((route, index) =>
                    <Route exact={route.exact} path={route.path} element={route.component} key={index}/>
                )}
            </Routes>
            // :
            // <Routes>
            //     {publicRoutes.map((route, index) =>
            //         <Route exact={route.exact} path={route.path} element={route.component} key={index}/>
            //     )}
            // </Routes>
    );
};

export default AppRouter;