import React from 'react';

const CreateModel = ({title}) => {
    return (
        <div>
            <div className="mb-5 text-lg font-bold mt-[-10px]">{title}</div>
            <form style={{width: 500}}>
                <div className="mb-6">
                    <label htmlFor="text"
                           className="block mb-2 text-sm font-normal text-gray-900 dark:text-white">Идентификатор:</label>
                    <input type="text" id="text"
                           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                           placeholder="" required></input>
                </div>

                <label htmlFor="message"
                       className="block mb-2 text-sm font-normal text-gray-900 dark:text-white">Наименование:</label>
                <textarea id="message" rows="4"
                          className="block min-h-[100px] mb-6 p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          placeholder=""></textarea>

                <div className="flex justify-between">
                    <div></div>
                    <div>
                        <button
                            type="button"
                            className="text-white mr-3 bg-orange-500 hover:bg-orange-700 focus:ring-4 focus:outline-none focus:ring-orange-500 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-orange-600 dark:hover:bg-orange-700 dark:focus:ring-orange-800"
                        >Отмена
                        </button>
                        <button
                            type="submit"
                            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        >Создать
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default CreateModel;