import React from 'react';
import Trash from "../icons/Trash";

const FactoryClassifierRow = ({data, index, deleteClassifier}) => {
    return (
        <tr className="bg-white border-b text-gray-900 dark:bg-gray-800 dark:border-gray-700">
            <th scope="row"
                className="px-6 py-2 font-medium text-center whitespace-nowrap dark:text-white border">
                {index + 1}
            </th>
            <td className="px-6 py-2 text-center border-r">
                {data.code}
            </td>
            <td className="px-6 py-2 border-r">
                {data.title}
            </td>
            <td className="px-6 py-2 w-12 text-center border-r">
                <div onClick={() => deleteClassifier(data.id)} title="Удалить вкладку">
                    <Trash/>
                </div>
            </td>
        </tr>
    );
};

export default FactoryClassifierRow;