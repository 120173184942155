import React from 'react';
import ArrowDown from "../icons/ArrowDown";
import ArrowRight from "../icons/ArrowRight";
import Trash from "../icons/Trash";
import {useNavigate} from "react-router-dom";

const UncResourceModelsTableRow = ({data, index, setData}) => {

    const navigate = useNavigate()

    return (
        <tr className="border-b border-[#343A40] bg-gray-300 dark:bg-gray-800 dark:border-gray-700">

            <th scope="row"
                className="px-6 py-1 w-8 font-medium text-center text-gray-900 whitespace-nowrap dark:text-white border-l border-[#343A40]">

                <div className="cursor-pointer hover:fill-amber-700" onClick={() => setData(index)}>
                    {
                        data.isOpen
                        ? <ArrowDown/>
                        : <ArrowRight/>
                    }
                </div>

            </th>
            <td className="px-6 py-1 text-black cursor-pointer" onClick={() => setData(index)}>
                {data.code}
            </td>
            <td className="px-6 py-1 text-black cursor-pointer" onClick={() => setData(index)}>
                {data.title}
            </td>
            <td className="px-1 py-1 text-center">
                <div className="hover:underline cursor-pointer" onClick={() => navigate(`/resource-models/${data.id}`)}>Перейти в РМ</div>
            </td>
            <td className="px-6 py-1 text-center">

            </td>
            <td className="px-6 py-1 text-center">

            </td>
            <td className="px-2 py-1 text-right text-black border-r border-[#343A40]">
                <div className="flex justify-end">
                    <div className="mr-1">
                        {(data.cost).toLocaleString('ru')}
                    </div>
                    <div className="cursor-pointer hover:fill-amber-700">
                        <Trash/>
                    </div>
                </div>
            </td>
        </tr>
    );
};

export default UncResourceModelsTableRow;