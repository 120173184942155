import Uncs from "../../pages/Uncs";
import ResourceModels from "../../pages/ResourceModels";
import {Navigate} from "react-router-dom";
import UncCard from "../uncs/UncCard";
import FsscmCatalog from "../catalogs/FsscmCatalog";
import BcCatalog from "../catalogs/BcCatalog";
import FactoriesCatalog from "../catalogs/FactoriesCatalog";
import ResourceModelCard from "../resource-models/ResourceModelCard";
import MarketAnalysis from "../../pages/MarketAnalysis";
import Gesn from "../../pages/Gesn";
import Fsem from "../../pages/Fsem";
import Fssco from "../../pages/Fssco";
import Registration from "../../pages/Registration";
import Login from "../../pages/Login";
import FactoryCard from "../catalogs/FactoryCard";

export const routes = [
    {path: '/uncs', component: <Uncs/>, exact: true},
    {path: '/uncs/:id', component: <UncCard/>, exact: true},
    {path: '/resource-models', component: <ResourceModels/>, exact: true},
    {path: '/resource-models/:id', component: <ResourceModelCard/>, exact: true},
    {path: '/market-analysis', component: <MarketAnalysis/>, exact: true},
    {path: '/gesn', component: <Gesn/>, exact: true},
    {path: '/fsem', component: <Fsem/>, exact: true},
    {path: '/fssco', component: <Fssco/>, exact: true},
    {path: '/fsscm', component: <FsscmCatalog/>, exact: true},
    {path: '/bc', component: <BcCatalog/>, exact: true},
    {path: '/factories', component: <FactoriesCatalog/>, exact: true},
    {path: '/factories/:id', component: <FactoryCard/>, exact: true},
    {path: '/*', component: <Navigate to="/uncs"/>, exact: true},
]

export const publicRoutes = [
    {path: '/registration', component: <Registration/>, exact: true},
    {path: '/login', component: <Login/>, exact: true},
    {path: '/*', component: <Navigate to="/login"/>, exact: true},
]