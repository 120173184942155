import React, {useEffect, useRef, useState} from 'react';
import BcService from "../../API/BcService";
import FactoryService from "../../API/FactoryService";

const UploadBcFile = ({title, setVisible, setIsSuccess, setVisibleNotify, setNotifyText, setNotifyColor, setNotifyData}) => {

    const [isLoading, setIsLoading] = useState(false)
    const [file, setFile] = useState(null)
    const [factories, setFactories] = useState([])
    const [selectedFactoryId, setSelectedFactoryId] = useState(0)
    const ref = useRef();

    const resetFile = () => {
        ref.current.value = "";
    }

    useEffect(() => {
        getFactories()
    }, [])

    const prepareFile = (event) => {
        event.preventDefault()
        setFile(event.target.files[0])
    }

    const getFactories = () => {
        const allFactories = FactoryService.getAll();
        allFactories.then((value) => {
            setFactories(value.data)
            setSelectedFactoryId(value.data[0].id)
        })
    }

    const postFile = () => {
        if (file) {
            setIsLoading(true)
            const postRmFile = BcService.postExcelBc(file, selectedFactoryId)
            postRmFile.then((value) => {
                setIsLoading(false)
                setVisible(false)
                setIsSuccess(true)
                setNotifyColor("border-green-600")
                setNotifyText(value.result)
                setNotifyData(value?.messages)
                setVisibleNotify(true)
                resetFile()
            }).catch((error) => {
                setIsLoading(false)
                setVisible(false)
                setIsSuccess(false)
                setNotifyColor("border-red-600")
                setNotifyText(error.response.data.result)
                setNotifyData(error.response.data?.messages)
                setVisibleNotify(true)
                resetFile()
            })
        }
    }

    return (
        <div>
            { isLoading ? (<div className="mb-6 font-bold text-xl">Идёт загрузка...</div>) : ("")}
            <div className="mb-5 text-lg font-bold mt-[-10px]">{title}</div>
            <div style={{width: 500}}>
                <div className="flex w-full justify-start items-center mb-6">
                    <div className="mr-2 w-60">
                        <select id="factories"
                                onChange={event => setSelectedFactoryId(Number(event.target.value))}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-1">
                            {
                                factories.map((item) => <option selected={item.id === selectedFactoryId}
                                                                       value={item.id}
                                                                       key={item.id}>{item.name}</option>)
                            }
                        </select>
                    </div>
                    <div className="w-full">
                        <input
                            disabled={isLoading}
                            onChange={prepareFile}
                            accept=".xlsx"
                            className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none"
                            id="file_input" name="rmFile" type="file" ref={ref}/>
                    </div>
                </div>

                <div className="flex justify-between">
                    <div></div>
                    <div>
                        <button
                            type="submit"
                            disabled={isLoading}
                            onClick={postFile}
                            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
                        >Загрузить
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UploadBcFile;