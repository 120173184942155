import React, {useState} from 'react';
import MainButton from "./buttons/MainButton";


const Header = ({count, countText, firstButtonText, secondButtonText, setVisibleFirstButton, setVisibleSecondButton}) => {

    const [loading, setLoading] = useState('')

    return (
        <div className="p-4 mb-4 border-2 border-gray-200 rounded-lg shadow-md bg-white sticky-5">
            <div className="flex items-start justify-between h-14 mb-4">
                <div className="w-full pr-4">
                    <input
                        type="text"
                        id="small-input"
                        placeholder="Поиск..."
                        className="block mr-4 w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    ></input>
                    <div className="mt-2 text-sm text-gray-500">{countText} <span
                        className="font-medium text-black">{count}</span></div>
                </div>
                {
                    firstButtonText ? <div className="ml-2"><MainButton width={150} onClick={() => setVisibleFirstButton(true)}>{firstButtonText}</MainButton></div> : ""
                }
                {
                    secondButtonText ? <div className="ml-2"><MainButton width={150} onClick={() => setVisibleSecondButton(true)}>{secondButtonText}</MainButton></div> : ""
                }
            </div>
            <strong>{loading}</strong>
        </div>
    );
};

export default Header;