import React, {useEffect, useState} from 'react';
import MainModal from "../modals/MainModal";
import {useNavigate, useParams} from "react-router-dom";
import MainButton from "../buttons/MainButton";
import ResourceModelPricesTable from "./ResourceModelPricesTable";
import CatalogList from "../catalogs/CatalogList";
import RmService from "../../API/RmService";
import ArrowDown from "../icons/ArrowDown";
import ArrowRight from "../icons/ArrowRight";
import ResourceModelSummaryTable from "./ResourceModelSummaryTable";
import ConfirmationModal from "../modals/ConfirmationModal";
import Toast from "../toast/Toast";

const ResourceModelCard = () => {
    const [visible, setVisible] = useState(false)
    const [confirmationVisible, setConfirmationVisible] = useState(false)
    const router = useNavigate()
    const params = useParams()

    // GET: /catalogs - получение списка справочников
    const catalogsList = [
        {id: 1, title: "ГЭСН - Затраты труда рабочих", code: "GESN"},
        {id: 2, title: "ФСЭМ - Машины и механизмы", code: "FSEM"},
        {id: 3, title: "ФССЦо - Оборудование", code: "FSSCO"},
        {id: 4, title: "ФССЦм - Материалы", code: "FSSCM"},
        {id: 5, title: "Бюллетень Цен", code: "BC"},
    ]

    const [selectedCatalogIndex, setSelectedCatalogIndex] = useState(0)
    const [smrVisible, setSmrVisible] = useState(false)
    const [rmVisible, setRmVisible] = useState(false)

    const [visibleNotify, setVisibleNotify] = useState(false)
    const [notifyText, setNotifyText] = useState('')
    const [notifyColor, setNotifyColor] = useState('border-green-600')
    const [notifyData, setNotifyData] = useState([])
    const [isSuccess, setIsSuccess] = useState(null)

    // GET: /resource-models/:id - общие данные о ресурсной модели

    const [resourceModel, setResourceModel] = useState({ cost: 0, code: '', title: '', catalogs: '', pricesCount: 0, prices: [], summary: [] })

    // GET: /resource-models/:id/prices - получение всех расценок этой ресурсной модели
    useEffect(() => {
        const rmData = RmService.getRm(params.id)
        rmData.then((value) => {
            setResourceModel(value.data)
        })
    }, [])

    const deleteRm = () => {
        const deleteRmRes = RmService.deleteRm(params.id)
        deleteRmRes.then((value) => {
            router(`/resource-models`)
            setConfirmationVisible(false)
            // setIsSuccess(true)
            // setNotifyColor("border-green-600")
            // setNotifyText(value.response.data.result)
            // setNotifyData(value.response.data?.messages)
            // setVisibleNotify(true)
        }).catch((error) => {
            setConfirmationVisible(false)
            setIsSuccess(false)
            setNotifyColor("border-red-600")
            setNotifyText(error.response.data.result)
            setNotifyData(error.response.data?.messages)
            setVisibleNotify(true)
        })
    }

    return (
        <div className="pl-10 pt-6 pr-10 mb-10 sm:ml-64">
            <MainModal visible={visible} setVisible={setVisible}>
                <CatalogList catalogData={catalogsList[selectedCatalogIndex]} setModalVisible={setVisible} isModal={true}/>
            </MainModal>
            <ConfirmationModal visible={confirmationVisible} setVisible={setConfirmationVisible} action={deleteRm}>Вы уверены, что хотите удалить эту РМ?</ConfirmationModal>
            <div className="flex justify-start items-start">
                <div
                    className="flex justify-center items-center bg-gray-300 w-10 h-10 border rounded-full cursor-pointer"
                    onClick={() => router(`/resource-models`)}
                >
                    <div>
                        <svg width="11" height="16" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 8L8 0L10.2 2.1L4.3 8L10.2 13.9L8 16L0 8Z" fill="#1C2E45" fillOpacity="1"/>
                        </svg>
                    </div>
                </div>
                <div className="ml-12 w-44">
                    <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
                        Идентификатор:
                    </div>
                    <div>
                        {resourceModel.code}
                    </div>
                </div>
                <div className="ml-12 w-[450px]">
                    <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
                        Наименование:
                    </div>
                    <div>
                        {resourceModel.title}
                    </div>
                </div>
                <div className="ml-12">
                    <div className="mr-2 text-sm font-normal text-gray-500 dark:text-gray-400">
                        Используемые справочники:
                    </div>
                    <div className="">
                        {resourceModel.catalogs}
                    </div>
                </div>
            </div>
            <div className="p-6 mt-10 border border-[#343A40] rounded-md shadow">
                <div className="flex justify-between items-end">
                    <div>
                        <label htmlFor="catalogs"
                               className="block mb-1 text-sm font-normal text-gray-500 dark:text-gray-400">Выбрать справочник:</label>
                        <select id="catalogs"
                                onChange={event => setSelectedCatalogIndex(Number(event.target.value)) }
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                            {
                                catalogsList.map((item, index) => <option selected={index === selectedCatalogIndex} value={index} key={item.id}>{item.title}</option>)
                            }
                        </select>
                    </div>
                    <div>
                        <MainButton onClick={() => setVisible(true)}>+ Добавить расценку</MainButton>
                    </div>
                </div>
                <div className="flex justify-start items-center mt-4 px-2 py-1 w-full border border-[#343A40] bg-gray-300 dark:bg-gray-800 dark:border-gray-700 cursor-pointer" onClick={() => setSmrVisible(!smrVisible)}>
                    <div className="cursor-pointer hover:fill-amber-700">
                        {
                            smrVisible
                                ? <ArrowDown/>
                                : <ArrowRight/>
                        }
                    </div>
                    <div className="ml-2 text-sm">
                        Расчёт СМР и оборудования
                    </div>
                </div>
                <div className={smrVisible ? "" : "hidden"}>
                    <ResourceModelPricesTable resourceModelsPricesList={resourceModel.prices}/>
                </div>
                <div className="flex justify-start items-center mt-0 px-2 py-1 w-full border border-[#343A40] bg-gray-300 dark:bg-gray-800 dark:border-gray-700 cursor-pointer" onClick={() => setRmVisible(!rmVisible)}>
                    <div className="cursor-pointer hover:fill-amber-700">
                        {
                            rmVisible
                                ? <ArrowDown/>
                                : <ArrowRight/>
                        }
                    </div>
                    <div className="ml-2 text-sm">
                        Итоги по ресурсной модели
                    </div>
                </div>
                <div className={rmVisible ? "" : "hidden"}>
                    <ResourceModelSummaryTable resourceModelsSummaryList={resourceModel.summary}/>
                </div>
                <div className="flex justify-between mt-6">
                    <div className="flex justify-start">
                        <div className="mr-2">
                            <MainButton>Выгрузить</MainButton>
                        </div>
                        <div>
                            <MainButton onClick={() => setConfirmationVisible(true)}>Удалить</MainButton>
                        </div>
                    </div>


                    <div>
                        <div className="flex justify-end">
                            <div className="mr-1 font-medium text-sm text-gray-700">Итого:</div>
                            <div className="text-sm font-medium">{(resourceModel.cost).toLocaleString('ru')} руб.</div>
                        </div>
                        {/*<div className="flex justify-end">*/}
                        {/*    <div className="mr-1 font-medium text-sm text-gray-700">Кол-во расценок:</div>*/}
                        {/*    <div className="text-sm font-medium">{resourceModel.pricesCount}</div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
            <Toast
                text={notifyText}
                notifyColor={notifyColor}
                dataArray={notifyData}
                isSuccess={isSuccess}
                setIsSuccess={setIsSuccess}
                isVisible={visibleNotify}
                setVisible={setVisibleNotify}
            />
        </div>
    );
};

export default ResourceModelCard;