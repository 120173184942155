import React, {useState} from 'react';
import RmService from "../../API/RmService";

const DropdownUtils = ({title}) => {

    const classes = "font-normal px-4 py-2 hover:bg-gray-700  dark:hover:bg-gray-700 dark:hover:text-white rounded-lg"
    const [isOpen, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false)

    const handleDropDown = () => {
        setOpen(!isOpen);
    }

    const setZeroToExtFour = () => {
        if (isLoading) return
        setIsLoading(true)
        const result = RmService.setRmZeroExtFour()
        result.then((value) => {
            setIsLoading(false)
        })
    }

    return (
        <li>
            <button id="dropdownDefaultButton" data-dropdown-toggle="dropdown"
                    className="flex justify-between w-[100%] mt-3 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    type="button"
                    onClick={handleDropDown}>{title}
                <svg className="w-4 h-4 ml-2" aria-hidden="true" fill="none"
                     stroke="currentColor" viewBox="0 0 24 24"
                     xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                </svg>
            </button>
            <div id="dropdown"
                 className={`z-10 w-50 rounded divide-y divide-gray-100  ${
                     isOpen ? "block" : "hidden"
                 }`}>
                <ul className="py-2 text-sm text-white dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
                    <li className="flex justify-between items-center cursor-pointer">
                        <div className={classes} onClick={setZeroToExtFour}>{isLoading ? "Идёт зануление..." : "Занулить затраты в Прил.4"}</div>
                    </li>
                </ul>
            </div>
        </li>
    );
};

export default DropdownUtils;