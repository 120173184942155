import React, {useState} from 'react';

const ResourceModel = ({data, index}) => {

    const [isVisibleInput, setVisibleInput] = useState(false)
    const showInput = () => {
        setVisibleInput(true)
    }
    const getQuantity = () => {
        if (isVisibleInput) {
            return (<input type="text" value={data.quantity} onfocusout={setVisibleInput(false)}/>)
        } else {
            return (<div className="underline cursor-pointer" onClick={showInput}>
                {data.quantity}
            </div>)
        }
    }

    return (
        <tr className="bg-white border-b border-[#343A40] dark:bg-gray-800 dark:border-gray-700">
            <th scope="row"
                className="px-6 py-1 w-8 font-normal text-center text-gray-900 whitespace-nowrap dark:text-white border-l border-[#343A40]">

                {index}
            </th>
            <td className="px-6 py-1 text-center text-black">
                {data.code}
            </td>
            <td className="px-6 py-1 text-black">
                {data.name}
            </td>
            <td className="px-6 py-1 text-center text-black">
                {data.unit}
            </td>
            <td className="px-6 py-1 text-center text-black">
                {data.quantity}
            </td>
            <td className="px-6 py-1 text-center text-black">
                {data.price}
            </td>
            <td className="px-2 py-1 text-right text-black border-r border-[#343A40]">
                <div className="flex justify-end">
                    {(data.cost).toLocaleString('ru')}
                </div>
            </td>
        </tr>
    );
};

export default ResourceModel;