import React, {useEffect, useState} from 'react';
import ArrowDown from "../icons/ArrowDown";
import ArrowRight from "../icons/ArrowRight";
import FirstNodeItem from "./FirstNodeItem";
import CatalogPriceTable from "./CatalogPriceTable";
import CatalogService from "../../API/CatalogService";

const CatalogChapter = ({data, setData, catalogData, checkedIds, setCheckedIds, index, isModal}) => {

    const classes = "ml-2 text-sm cursor-pointer hover:underline"

    // GET: /catalogs/:id/children - получение списка дочерних элементов первого уровня у ноды, где id = data.id
    const [catalogChildren, setCatalogChildren] = useState([])
    const [typeChildren, setTypeChildren] = useState('node')
    const [priceTitles, setPriceTitles] = useState({firstPriceTitle: '', secondPriceTitle: ''})

    const getChildren = (id, childId) => {
        const catalogChildrenData = CatalogService.getCatalogChildren(id, childId)
        catalogChildrenData.then((value) => {
            if (value.type === "price") {
                setPriceTitles({
                    firstPriceTitle: value.firstPriceTitle,
                    secondPriceTitle: value.secondPriceTitle,
                })
            }
            setTypeChildren(value.type)
            setCatalogChildren(value.data)
        })
    }

    const setOpenClosed = (index) => {
        let changedItem = catalogChildren[index]
        changedItem.isOpen = !changedItem.isOpen
        setCatalogChildren([...catalogChildren.slice(0, index), changedItem, ...catalogChildren.slice(index + 1)])
    }

    const nodeClick = () => {
        getChildren(catalogData.id, data.id)
        setData(index)
    }

    return (
        <div>
            <div className="flex justify-start items-center border h-8 w-full">
                <div className="ml-2 cursor-pointer hover:fill-amber-700" onClick={nodeClick}>
                    {
                        data.isOpen
                            ? <ArrowDown/>
                            : <ArrowRight/>
                    }
                </div>
                <div className={data.isOpen ? classes + " font-bold" : classes} onClick={nodeClick}>
                    {data.title}
                </div>
            </div>

            <div className={data.isOpen ? "h-full" : "invisible h-0"}>
                {
                    typeChildren === "node"
                        ? catalogChildren.map((item, index) => <FirstNodeItem isModal={isModal} data={item}
                                                                              catalogData={catalogData}
                                                                              index={index} key={item.id}
                                                                              setData={setOpenClosed}
                                                                              checkedIds={checkedIds}
                                                                              setCheckedIds={setCheckedIds}/>)
                        : <CatalogPriceTable isModal={isModal} firstPriceTitle={priceTitles.firstPriceTitle}
                                             secondPriceTitle={priceTitles.secondPriceTitle} data={catalogChildren}
                                             checkedIds={checkedIds} setCheckedIds={setCheckedIds}/>
                }
            </div>
        </div>
    );
};

export default CatalogChapter;