import React from 'react';

const MultiPriceIcon = () => {
    return (
        <div>
            <svg width="16" height="16" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12.5" cy="12.5" r="12" fill="#FF9119" stroke="#F5F5F5"/>
                <path d="M7.9233 8.27273H9.85795L12.4489 14.5966H12.5511L15.142 8.27273H17.0767V17H15.5597V11.0043H15.4787L13.0668 16.9744H11.9332L9.52131 10.9915H9.44034V17H7.9233V8.27273Z" fill="white"/>
            </svg>
        </div>
    );
};

export default MultiPriceIcon;