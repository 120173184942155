import React, {useEffect, useState} from 'react';
import CatalogList from "./CatalogList";
import CatalogService from "../../API/CatalogService";

const FsemCatalog = () => {
    // GET: /catalogs/fsem - получение списка справочников

    const catalogData = {id: 2, title: "ФССЦм - Материалы", code: "FSEM"}

    return (
        <div className="pl-10 pt-6 pr-10 sm:ml-64">
            <CatalogList isModal={false} catalogData={catalogData}/>
        </div>
    );
};

export default FsemCatalog;