import React, {useContext, useEffect, useState} from 'react';
import UserService from "../API/UserService";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../context";
import AuthService from "../API/AuthService";

const Login = () => {

    const navigate = useNavigate()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const {isAuth, setIsAuth} = useContext(AuthContext)

    const login = (event) => {
        event.preventDefault()

        const loginUser = UserService.login({
            email,
            password
        })

        loginUser.then((value) => {
            if (value.status === 200) {
                localStorage.setItem('access_token', value.data?.access_token)
                setIsAuth(true)
                navigate('/uncs')
            }
        })
    }

    useEffect(() => {
        // if (localStorage.getItem('access_token') !== null) {
        //     navigate('/uncs')
        // }
    }, [])

    return (
        <div className="flex justify-center items-center">
            <form className="mt-40 p-6 border">
                <div className="mb-6 text-center text-2xl font-bold">Вход в ситему</div>
                <div className="mb-6">
                    <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Введите логин</label>
                    <input type="email" id="email"
                           value={email}
                           onChange={event => setEmail(event.target.value)}
                           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                           placeholder="" required/>
                </div>
                <div className="mb-6">
                    <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Введите пароль</label>
                    <input type="password" id="password"
                           value={password}
                           onChange={event => setPassword(event.target.value)}
                           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                           required/>
                </div>

                <button type="submit"
                        onClick={event => login(event)}
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Войти
                </button>
            </form>

        </div>
    );
};

export default Login;