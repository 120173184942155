import React from 'react';
import ResourceModelEditable from "./ResourceModelEditable";
import ResourceModel from "./ResourceModel";
import ResourceModelSummaryTableRow from "./ResourceModelSummaryTableRow";

const ResourceModelSummaryTable = ({resourceModelsSummaryList}) => {
    const rowCount = resourceModelsSummaryList.length

    return (
        <div className="mt-0">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                    <th scope="col" className="px-3 py-3 text-center border border-[#343A40]">
                        Наименование
                    </th>
                    <th scope="col" className="px-3 py-3 text-center border border-[#343A40]">
                        Сметная стоимость в ценах на 01.01.2023
                        (руб.)
                    </th>
                    <th scope="col" className="px-3 py-3 text-center border border-[#343A40]">
                        Удельный вес,
                        (в СМР)
                    </th>
                    <th scope="col" className="px-3 py-3 text-center border border-[#343A40]">
                        Удельный вес, %
                        (от всего по РМ)
                    </th>
                </tr>
                </thead>
                <tbody>
                {resourceModelsSummaryList.map((item, index) => {
                    return <ResourceModelSummaryTableRow item={item} isEndRow={rowCount === index + 1} key={item.id}/>
                })}
                </tbody>
            </table>
        </div>
    );
};

export default ResourceModelSummaryTable;