import React, {useState} from 'react';
import ArrowDown from "../icons/ArrowDown";
import ArrowRight from "../icons/ArrowRight";
import CatalogPriceTableBc from "./CatalogPriceTableBc";
import BcService from "../../API/BcService";

const CatalogChapterBc = ({data, setData, checkedIds, setCheckedIds, index, bcShowParam, isModal}) => {

    const classes = "ml-2 text-sm cursor-pointer hover:underline"

    const [priceTable, setPriceTable] = useState({columns: [], data: []})

    const getPriceTable = () => {
        const prices = BcService.getBc(data.id, bcShowParam)
        prices.then((value) => {
            setPriceTable(value.data)
        })
    }

    const titleClick = () => {
        getPriceTable()
        setData(index)
    }

    return (
        <div>
            <div className="flex justify-start items-center border h-8 w-full">
                <div className="ml-2 cursor-pointer hover:fill-amber-700" onClick={titleClick}>
                    {
                        data.isOpen
                            ? <ArrowDown/>
                            : <ArrowRight/>
                    }
                </div>
                <div className={data.isOpen ? classes + " font-bold" : classes} onClick={titleClick}>
                    {data.code} - {data.title}
                </div>
            </div>

            <div className={data.isOpen ? "h-full" : "invisible h-0"}>
                <CatalogPriceTableBc isModal={isModal} key={data.id} priceTable={priceTable} checkedIds={checkedIds}
                                     setCheckedIds={setCheckedIds}/>
            </div>

        </div>
    );
};

export default CatalogChapterBc;