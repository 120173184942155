import React from 'react';
import {Link} from "react-router-dom";
import DownloadIcon from "../icons/DownloadIcon";
import DownloadFileService from "../../API/DownloadFileService";
import CurrencyService from "../services/CurrencyService";

const ResourceModelsTableRow = ({data, index}) => {

    const downloadRmFile = (filePath) => {
        const download = DownloadFileService.downloadFile(filePath)
        download.then((value) => {
            // показать уведомление об успешном скачивании
        })
    }

    return (
        <tr className="bg-white border-b text-gray-900 dark:bg-gray-800 dark:border-gray-700">
            <th scope="row"
                className="px-6 py-2 font-medium text-center whitespace-nowrap dark:text-white border">
                {index}
            </th>
            <td className="px-6 py-2 text-center border-r hover:underline">
                <Link to={`/resource-models/${data.id}`}>{data.code}</Link>
            </td>
            <td className="px-6 py-2 border-r hover:underline">
                <Link to={`/resource-models/${data.id}`}>{data.title}</Link>
            </td>
            <td className="px-6 py-2 text-right border-r">
                {CurrencyService.sumInThousands(data.cost)}
            </td>
            <td className="px-6 py-2 w-12 text-center border-r">
                <div onClick={() => downloadRmFile(data.file_path)}>
                    <DownloadIcon/>
                </div>
            </td>
        </tr>
    );
};

export default ResourceModelsTableRow;