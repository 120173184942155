import React, {useEffect, useState} from 'react';
import CatalogChapter from "./CatalogChapter";
import MainButton from "../buttons/MainButton";
import CatalogChapterBc from "./CatalogChapterBc";
import CatalogService from "../../API/CatalogService";
import BcService from "../../API/BcService";
import RegionService from "../../API/RegionService";

const CatalogList = ({setModalVisible, isModal, isRegions, importButton, exportButton, catalogData}) => {

    const classes = "w-full min-w-[900px] max-w-[1200px] min-h-[450px] max-h-[calc(100vh-200px)] overflow-y-auto"

    const [chapters, setChapters] = useState([])
    const [chaptersBc, setChaptersBc] = useState([])
    const [checkedIds, setCheckedIds] = useState([])
    const [checkedBcIds, setCheckedBcIds] = useState([])
    const [catalog, setCatalog] = useState({})
    const [catalogChildren, setCatalogChildren] = useState([])
    const [typeChildren, setTypeChildren] = useState('')
    const [regionsList, setRegionsList] = useState([])
    const [selectedRegionId, setSelectedRegionId] = useState(0)
    const [bcShowParam, setBcShowParam] = useState('all')

    useEffect(() => {
        const catalogD = CatalogService.getCatalogByCode(catalogData.code)
        catalogD.then((value) => {
            setCatalog(value.data)
            if (value.data.code === "BC") {
                getBc()
            } else {
                getChildren(catalogData.id, 0)
            }
        })

        const regions = RegionService.getAll()
        regions.then((value) => {
            setRegionsList(value.data)
        })
    }, [])

    const getBc = () => {
        const bcTitles = BcService.getAll()
        bcTitles.then((value) => {
            setChaptersBc(value.data)
        })
    }

    const getChildren = (id, childId) => {
        const catalogChildrenData = CatalogService.getCatalogChildren(id, childId)
        catalogChildrenData.then((value) => {
            setTypeChildren(value.type)
            setCatalogChildren(value.data)
        })
    }

    const setOpenClosed = (index) => {
        let changedItem = catalogChildren[index]
        changedItem.isOpen = !changedItem.isOpen
        setCatalogChildren([...catalogChildren.slice(0, index), changedItem, ...catalogChildren.slice(index + 1)])
    }

    const setOpenClosedBc = (index) => {
        let changedItem = chaptersBc[index]
        changedItem.isOpen = !changedItem.isOpen
        setChaptersBc([...chaptersBc.slice(0, index), changedItem, ...chaptersBc.slice(index + 1)])
    }

    const postPricesToResourceModel = () => {
        setCheckedIds([])
        setCheckedBcIds([])
        setModalVisible(false)
    }

    return (
        <div className="mb-10">
            <div className="flex justify-between mb-4">
                <div className="flex">
                    <div className="mr-2 font-bold text-lg dark:text-gray-400">
                        Справочник:
                    </div>
                    <div className="text-lg">{catalog.title}</div>
                </div>
                <div className="flex justify-center items-center">
                    {
                        exportButton
                        ?
                        (
                            <div className="mr-4">
                                <div className="flex justify-end">
                                    <div>
                                        {exportButton}
                                    </div>
                                </div>
                            </div>
                        )
                        : ""
                    }
                    <div>
                        {
                            importButton
                                ?
                                (
                                    <div className="flex justify-end">
                                        <div>
                                            {importButton}
                                        </div>
                                    </div>
                                )
                                : ""
                        }
                    </div>
                </div>
            </div>
            <div className="flex justify-start items-center">
                <div className="w-46 mb-4">
                    <label htmlFor="showmode" className="block mb-1 text-sm font-normal text-black">Режим отображения:</label>
                    <select id="showmode"
                            onChange={(event) => {
                                setBcShowParam(event.target.value)
                            }}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                        <option className="font-medium" value="all">Все расценки</option>
                        <option className="font-medium" value="only_in_rm">Только расценки из РМ</option>
                    </select>
                </div>
                {
                    isRegions
                        ?
                        (
                            <div className="w-96 mb-4 ml-4">
                                <label htmlFor="regions" className="block mb-1 text-sm font-normal text-black">Регион:</label>
                                <select id="regions"
                                        onChange={(event) => setSelectedRegionId(Number(event.target.value))}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                    <option className="font-medium">Регион не выбран</option>
                                    {
                                        regionsList.map((item, index) => <option value={item.id} key={item.id}>{item.name}</option>)
                                    }
                                </select>
                            </div>
                        )
                        : ""
                }
            </div>

            <div className={isModal ? classes : ""}>
                {
                    catalog.code === "BC"
                    ? chaptersBc.map((item, index) => <CatalogChapterBc isModal={isModal} data={item} key={item.id} index={index} setData={setOpenClosedBc} checkedIds={checkedBcIds} setCheckedIds={setCheckedBcIds} bcShowParam={bcShowParam}/>)
                    : catalogChildren.map((item, index) => <CatalogChapter isModal={isModal} type={typeChildren} data={item} catalogData={catalogData} key={item.id} index={index} setData={setOpenClosed} checkedIds={checkedIds} setCheckedIds={setCheckedIds}/>)
                }
            </div>
            {
                isModal
                ?
                    (
                        <div className="flex justify-end mt-3">
                            <div>
                                <MainButton onClick={postPricesToResourceModel} disabled={checkedIds.length === 0 && checkedBcIds.length === 0}>Сохранить</MainButton>
                            </div>
                        </div>
                    )
                    : ""
            }

        </div>
    );
};

export default CatalogList;