import React from 'react';

const ArrowRight = () => {
    return (
        <div className="cursor-pointer hover:fill-amber-700">
            <svg width="7" height="10" viewBox="0 0 7 10" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 10H2L7 5L2 0H0L5 5L0 10Z"  fillOpacity="1"/>
            </svg>
        </div>
    );
};

export default ArrowRight;