import React from 'react';

const PlusIcon = () => {
    return (
        <div className="flex justify-center items-center cursor-pointer hover:fill-amber-700">
            <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" width="14px" height="14px" fillRule="evenodd"><path fillRule="evenodd" d="M 11 2 L 11 11 L 2 11 L 2 13 L 11 13 L 11 22 L 13 22 L 13 13 L 22 13 L 22 11 L 13 11 L 13 2 Z"/></svg>
        </div>
    );
};

export default PlusIcon;