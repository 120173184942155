import React from 'react';
import GesnCatalog from "../components/catalogs/GesnCatalog";
import FsemCatalog from "../components/catalogs/FsemCatalog";
import FsscoCatalog from "../components/catalogs/FsscoCatalog";

const Fssco = () => {
    return (
        <div>
            <FsscoCatalog/>
        </div>
    );
};

export default Fssco;